/* ================= ERROR PAGES =================== */

.ops-maintenance-error-section {
  position: absolute;
  background: var(--white, #ffffff);
  color: var(--dark-blue, #112949);
  height: 100%;
  width: calc(100% - 8vh);
  right: 0px;
}

.ops-maintenance-head {
  position: absolute;
  padding: 20px;
  display: flex;
  font-size: 50px;
  align-items: center;
  height: 8vh;
  width: calc(100vw - 75% - 6vh);
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
}

.ops-maintenance-error-msg {
  width: 50%;
  position: absolute;
  transform: translateY(8vh);
  margin: 20px;
}

.ops-maintenance-error-msg-big {
  display: block;
  font-size: 64px;
}

.ops-maintenance-error-msg-small {
  display: block;
  font-size: 22px;
}

.ops-maintenance-error-img {
  position: absolute;
  height: 70%;
  right: 30px;
  bottom: 30px;
}

.ops-loader-modal {
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 7vh);
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 3;
}

.ops-loader {
  background: var(--white, #ffffff);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  height: fit-content;
}

.spin-loader {
  display: flex;
  color: var(--dark-blue);
  font-size: 4rem;
  animation: rotation 2s infinite linear;
}

.img-loader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3vh;
}

.img-loader-img {
  height: 5vh;
  animation: bounce-obj 1s infinite linear;
  background-color: var(--dark-blue);
  border-radius: 6px;
}

.img-loader::after {
  position: relative;
  content: "";
  display: inline-block;
  height: 1vh;
  width: 5.5vh;
  border-radius: 50%;
  border: 1px solid var(--dark-grey);
  background-color: var(--light-grey);
  animation: bounce-shadow 1s infinite linear;
}

@media only screen and (max-width: 500px) {
  /* ================== Loader ==================== */
  .ops-loader-modal {
    width: 100vw;
    height: 100vh;
    z-index: 5;
  }

  .ops-loader {
    font-size: var(-xxs-font);
  }

  .spin-loader {
    display: flex;
    font-size: 4vh;
    animation: rotation 2s infinite linear;
  }
}
