/* ========= SIDENAV CSS ========== */

/* .ops-sidenav {
    position: relative;
    grid-area: sidenav;
    height: 100%;
    width: 8vh;
    background: linear-gradient( 170deg, rgba(17, 41, 73, 1) 0%, rgba(27, 78, 114, 1) 48%, rgba(60, 195, 242, 1) 100%);
    text-align: center;
} */

/* .ops-sidenav-btns-container{
    display: grid;
    justify-content: center;
    align-items: flex-start;
    grid-template-rows: repeat(3, auto);
    row-gap: 1vh;
    margin: 1vh 0px;
}

.ops-sidenav-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 1vh 0px;
    border-radius: 5px;
    color: white;
    outline: none;
    transition: 300ms;
    box-sizing: border-box;
}

.ops-sidenav-btn:hover {
    background-color: rgba(182, 179, 179, 0.34);
}

.ops-sidenav-brand:hover {
    background-color: transparent;
}

.ops-settings-btn {
    position: absolute;
    width: 100%;
    top: 90%;
    left: 0px;
}

.ops-sidenav-icon {
    color: white;
    width: 5vh;
    transition: 200ms;
    height: 4vh;
}

.ops-archive-btn-icon{
    font-size: 4vh;
}

.ops-settings-btn {
    position: absolute;
    top: 90%;
    left: 0px;
} */

.ops-sidenav {
  position: relative;
  grid-area: sidenav;
  height: 100vh;
  padding-top: 10px;
  row-gap: 5px;
  column-gap: 5px;
  background: var(--vertical-blue-black-gradient);
  text-align: center;
  -webkit-box-shadow: 0px 2px 4px 0px var(--shadow-color);
  -moz-box-shadow: 0px 2px 4px 0px var(--shadow-color);
  box-shadow: 0px 2px 4px 0px var(--shadow-color);
  z-index: 2;
  background: linear-gradient(
    170deg,
    rgba(17, 41, 73, 1) 0%,
    rgba(27, 78, 114, 1) 48%,
    rgba(60, 195, 242, 1) 100%
  );
}

.ops-ez-logo {
  position: relative;
  display: inline-block;
  height: 6vh;
  width: 6vh;
  margin: 0px auto 10px auto;
}

.ops-ez-logo img {
  position: relative;
  height: 100%;
  width: 100%;
}

.ops-sidenav-btn {
  position: relative;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 10px auto;
  background: none;
  border: none;
  border-radius: 5px;
  color: white;
  outline: none;
  transition: 200ms;
  box-sizing: border-box;
}

.ops-mobile-sidebar-show-btn {
  display: none;
}

/* .ops-dashboard-sidebar-show-btn{
    transform: scale(1.15);
  } */

.ops-settings-btn {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0%;
  left: 0px;
}

.ops-archive-btn {
  font-size: 4vh;
}

.ops-sidenav-icon {
  color: white;
  width: 4vh;
  height: 4vh;
  font-size: var(--xxl-font);
  transition: 200ms;
}

.ops-sidenav-btn-active {
  background: rgba(216, 213, 213, 0.35);
}

.ops-sidenav-btn-hover {
  background: rgba(216, 213, 213, 0.35);
}

@media only screen and (max-width: 500px) {
  /* ============ SIDENAV ============= */
  /* .ops-sidenav{
        width: 100%;
        display: flex;
        align-items: center;
    }

    .ops-sidenav-brand{
        display: none;
    }

    .ops-sidenav-btns-container{
        grid-template-columns: repeat(2, auto);
        row-gap: 0vh;
        column-gap: 1vw;
        justify-content: space-between;
        width: 100%;
        margin: 0vh 2vw;
        align-items: center;
        height: fit-content;
    } */

  /* ========= SIDENAV CSS ========== */

  .ops-sidenav {
    position: fixed;
    bottom: 0px;
    height: 50px;
    /* grid-area: sidenav; */
    display: flex;
    row-gap: 5px;
    column-gap: 5px;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: end;
    /* padding-top: 0px; */
    padding: 2px 10px;
    z-index: 4;
  }

  .ops-sidenav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin: 0px;
    padding: 5px;
  }
  .ops-sidenav-btn-hover {
    background: none;
  }

  .ops-sidenav-btn-active {
    background: rgba(216, 213, 213, 0.35);
  }

  .ops-sidenav-icon {
    width: 5vh;
    height: 5vh;
  }

  .ops-mobile-sidebar-show-btn {
    display: inline-flex;
    transform: scale(0.9);
  }

  .ops-dashboard-sidebar-show-btn {
    display: none;
  }

  .ops-ez-logo {
    display: none;
  }

  .ops-settings-btn {
    position: relative;
    width: 55px;
    top: auto;
    left: auto;
  }

  .ops-archive-btn {
    display: inline-flex;
    transform: scale(1.15);
    font-size: 28px;
    margin-right: auto;
    /* align-self: flex-start; */
  }

  .ops-requester-btn {
    position: relative;
    right: 12vh;
  }
}
