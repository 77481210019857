.ops-dashboard-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 7vh auto;
  grid-template-columns: 7vh 25% auto;
  grid-template-areas: "sidenav sidebar topnav" "sidenav sidebar dashboard";
}

.empty-data-msg {
  font-size: 0.9vw;
  font-style: italic;
}

.ops-dashboard-back-btn {
  display: none;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  background: transparent;
  border: none;
  color: var(--dark-blue, black);
}

.ops-btns {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 1vh 2vh;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: var(--s-font);
  color: white;
  min-width: 6vw;
}
.ops-btns:hover {
  opacity: 0.8;
}

.ops-dashboard-input {
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--s-font);
  width: 100%;
  padding: 7px 10px;
  border-radius: 6px;
  border: none;
  outline: none;
  background: rgba(17, 41, 73, 0.4);
  color: var(--dark-blue, #112949);
  border: 0.5px solid var(--black, #03080f);
}

.ops-input-error {
  font-size: var(--xs-font);
  margin-top: 2px;
  margin-left: 25px;
  width: 90%;
  height: 100%;
  vertical-align: top;
  height: fit-content;
  word-wrap: break-word;
  color: var(--orange, #ea7b2c);
}

/* ========== OPS DASHBOARD MODAL WINDOWS =============*/

.ops_modal {
  position: absolute;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background: rgba(17, 41, 73, 0.7);
}

/* ============== OPS DELIVER FILE MODAL ================== */

.file-container {
  width: auto;
  height: auto;
  background: white;
}

.file-container-head {
  position: relative;
  display: inline-block;
  left: 2.2vh;
  text-align: center;
  margin: 2vh auto 0px auto;
  font-size: 1.22vw;
  color: var(--dark-blue, #112949);
}

.file-container-head-new {
  position: relative;
  display: inline-block;
  /* left: 15px; */
  text-align: center;
  /* margin: 10px auto; */
  font-size: 1.22vw;
}

.file-container-close-btn {
  position: relative;
  float: right;
  outline: none;
  /* height: 50px;
    width: 50px; */
  display: flex;
  z-index: 1;
  font-size: 2.1vw;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 300ms;
  color: var(--dark-blue, #112949);
}
.file-container-close-btn svg {
  height: 25px;
  width: 25px;
}

.file-container-close-btn img {
  position: relative;
  height: 70%;
  width: 70%;
}

/* ============ DELIVER FILE MODAL ========================= */

.file-deliver-label {
  text-align: left;
  display: inline-block;
  margin-top: 1.5vh;
  width: 150px;
  width: 40%;
  color: grey;
  font-size: 0.9vw;
  margin-bottom: 5px;
}

.file-deliver-label-new {
  text-align: left;
  display: inline-block;
  width: 150px;
  width: 40%;
  color: grey;
  font-size: 0.9vw;
  /* margin: 5px 0px 5px 1.5vw; */
  margin: 5px 0px 5px 0px;
}

.deliver-file-contain {
  position: absolute;
  top: 25vh;
  left: 45vw;
  background: white;
  color: var(--dark-blue, #112949);
  width: 40vw;
  height: 40vh;
  border-radius: 1vh;
}

.deliver-file-contain-new {
  position: absolute;
  top: 10vh;
  left: 45vw;
  background: white;
  color: var(--dark-blue, #112949);
  padding: 15px;
  width: 30vw;
  /* height: 45vh; */
  height: fit-content;
  border-radius: 1vh;
}

.ops-new-version-form {
  position: relative;
  display: grid;
  grid-template-rows: repeat(4, min-content);
  grid-template-columns: repeat(2, 50%);
  width: 100%;
  grid-template-areas:
    "update-version-assignment-id update-version-checkbox"
    "update-version-input update-version-input"
    "update-version-notes update-version-notes"
    "update-version-btns update-version-btns";
}

#update-version-assignment-id {
  grid-area: update-version-assignment-id;
}
#update-version-checkbox {
  grid-area: update-version-checkbox;
}
#update-version-input {
  grid-area: update-version-input;
}
#update-version-notes {
  grid-area: update-version-notes;
}
#update-version-btns {
  grid-area: update-version-btns;
}

#update-deliver-time-checkbox {
  margin: 0.8vh 0px;
  width: fit-content;
}

.file-deliver-input {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0.8vh;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 0.9vw;
  margin: 0px auto;
  outline: none;
}

.file-deliver-input-new {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0.8vh 0px;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 0.9vw;
  outline: none;
}

.file-deliver-input-new-file {
  padding: 0.8vh;
}

.file-deliver-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: red;
}

.file-deliver-input::-moz-placeholder {
  /* Firefox 19+ */
  color: red;
}

.file-deliver-input:-ms-input-placeholder {
  /* IE 10+ */
  color: red;
}

.file-deliver-input:-moz-placeholder {
  /* Firefox 18- */
  color: red;
}

.delivery-notes-input {
  height: 13vh;
  width: 35vw;
}

.delivery-notes-input-new {
  height: 10vh;
  width: 100%;
  /* width: 26.5vw; */
  /* margin-left: 1.5vw; */
}

.file-deliver1 {
  float: left;
  width: 50%;
}

.delivery-notes-label {
  vertical-align: top;
  top: 60px;
}

.delivery-notes-label-new {
  vertical-align: top;
  top: 60px;
  /* margin-left: 1.5vw; */
}

.deliver-new-version-btn-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.file-container-submit-btn {
  position: relative;
  display: block;
  width: 10vw;
  padding: 0.8vh;
  height: auto;
  margin-top: 1vh;
  margin-left: 5vw;
  font-size: 1vw;
  outline: none;
  border-radius: 5px;
  border: none;
  color: white;
  background: var(--orange, #ea7b2c);
  cursor: pointer;
}

.file-container-submit-btn:hover {
  opacity: 0.85;
}

.file-container-submit-btn:disabled {
  opacity: 0.5;
  cursor: default;
}

.file-container-submit-btn2 {
  position: relative;
  display: block;
  width: 10vw;
  padding: 0.8vh;
  margin-top: 8vh;
  margin-left: 5vw;
  font-size: 1vw;
  outline: none;
  border-radius: 5px;
  border: 1px solid grey;
  color: grey;
  background: var(--white, #ffffff);
  cursor: pointer;
}

.file-container-submit-btn2:hover {
  color: var(--orange, #ea7b2c);
  border: 1px solid var(--orange, #ea7b2c);
}

.secure-key-cancel-btn {
  position: relative;
  width: 8vw;
  font-size: 1vw;
  outline: none;
  border-radius: 5px;
  border: 1px solid grey;
  color: grey;
  background: white;
  cursor: pointer;
  /* left: 2vw;
    bottom: 1.4vh; */
  padding: 0.8vh;
}

.file-container-submit-btn-active {
  background: var(--orange, #ea7b2c);
}

.p-2 {
  padding: 2%;
  padding-top: 1%;
}

/* ============== COPY SECURE KEY MODAL =================== */

.copy-secure-key-container {
  position: absolute;
  top: 10vh;
  left: 45vw;
  background: white;
  color: var(--dark-blue, #112949);
  padding: 15px;
  width: 40vw;
  height: auto;
  min-height: 35vh;
  border-radius: 1vh;
}

.copy-secure-key-head {
  padding: 0;
  display: inline-block;
  font-size: 1.22vw;
  margin-bottom: 10px;
}

.copy-secure-key-label {
  position: relative;
  /* padding-left: 2vw; */
  color: grey;
  font-size: 0.9vw;
  text-align: left;
  margin-bottom: 5px;
}

.copy-secure-key-value {
  position: relative;
  text-align: left;
  font-size: 1vw;
  margin-left: 0.5vw;
  padding: 5px;
}

.copy-key-delivery-notes-value {
  display: block;
  width: 37vw;
  min-height: 4vh;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 5px;
  margin-left: 0.5vw;
  margin-right: 2vw;
}

.copy-key-delivery-notes-value::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: white;
}

.copy-secure-key-edit-btn {
  position: relative;
  width: 8vw;
  height: 4vh;
  font-size: 1.1vw;
  outline: none;
  border-radius: 5px;
  border: 1px solid var(--orange, #ea7b2c);
  color: var(--orange, #ea7b2c);
  /* margin-top: 3vh; */
  background: white;
  cursor: pointer;
  /* left: 2vw; */
  /* bottom: 1.4vh; */
  padding: 0.5vh;
}

.copy-secure-key-copy-btn {
  position: relative;
  width: 8vw;
  height: auto;
  font-size: 1vw;
  outline: none;
  border-radius: 5px;
  border: none;
  color: white;
  background: var(--orange, #ea7b2c);
  /* right: 1vw; */
  cursor: pointer;
  /* bottom: 1.4vh; */
  padding: 0.9vh;
}

.copy-secure-key-copy-btn:disabled {
  opacity: 0.5;
  cursor: default;
}

.file-error {
  font-size: 0.9vw;
  margin-top: 2px;
  margin-left: 1.5vw;
  width: 90%;
  height: 100%;
  vertical-align: top;
  height: fit-content;
  word-wrap: break-word;
  color: red;
}

.file-error-source {
  font-size: 0.9vw;
  margin-top: 2px;
  margin-left: 0.2vw;
  width: 90%;
  height: 100%;
  vertical-align: top;
  height: fit-content;
  word-wrap: break-word;
  color: red;
}

/* ============ DASHBOARD ============== */

.file-icon {
  position: relative;
  display: inline-block;
}

.ops-file-type {
  position: absolute;
  font-size: 0.7rem;
  right: -5px;
  top: -7px;
  font-weight: bold;
  color: white;
  padding: 3px;
  border-radius: 5px;
  background: var(--orange, #ea7b2c);
}

/* =========== DASHBOARD ======== */

.ops-dashboard {
  position: relative;
  grid-area: dashboard;
  width: auto;
  color: var(--dark-blue, #112949);
  background-color: #ebf2f5;
  overflow-y: auto;
}

.ops-dashboard::-webkit-scrollbar {
  display: none;
}

/* ================== Bottom Dashboard =============== */

.bottom-dashboard {
  position: relative;
  display: block;
  background-color: transparent;
  font-size: 1vw;
  display: flex;
  flex-direction: column;
  max-height: 65vh;
}

.bottom-dashboard2 {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: inset 0px 2px 4px 0px rgb(117 116 116 / 34%);
  background-color: white;
  padding-top: 10px;
}

.p-1 {
  padding: 2%;
  background-color: #ebf2f5;
  padding-bottom: 0px;
}
.padding-delivery {
  padding: 2%;
  background-color: #ebf2f5;
}
.bottom-dashboard-tab {
  height: auto;
  border-radius: 5px;
  border-top: none;
  background-color: white;
  padding: 1vh;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: 0px 0px 4px 2px rgba(117, 116, 116, 0.34);
}

.ops-dashboard-selector {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 1.5px 1.6vw;
  background: white;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
}

.bottom-dashboard-tab-btn {
  position: relative;
  display: inline-block;
  background: transparent;
  border: none;
  outline: none;
  color: grey;
  padding: 1vh 0px;
  font-size: 0.9vw;
  cursor: pointer;
  letter-spacing: 0.1vh;
}

.bottom-dashboard-tab-btn:hover {
  color: var(--dark-blue, #112949);
}

.ops-dashboard-selector-btn-container {
  display: flex;
  flex-direction: row;
  column-gap: 1vw;
  justify-content: center;
  align-items: center;
}

.bottom-dashboard-share-tab-btn {
  /* display: none; */
  margin-right: 0px;
  padding: 1vh 0.2vw;
  font-size: 1.2vw;
}

.bottom-dashboard-share-tab-btn svg {
  display: flex;
  align-items: center;
}

.ops-dashboard-selector-btn-active {
  color: var(--dark-blue, #112949);
}

.ops-dashboard-selector-btn-active::after {
  position: absolute;
  bottom: -2px;
  left: 0px;
  content: "";
  height: 0.6vh;
  width: 100%;
  margin: auto;
  background: var(--dark-blue, #112949);
}

.bottom-dashboard-sync {
  position: relative;
  border: none;
  display: flex;
  font-size: 1.2vw;
  background: none;
}
.bottom-dashboard-sync-rotate {
  animation: rotation 2s infinite linear;
}
/* .bottom-dashboard-sync:hover::after{
    content: "Sync Data from Google Sheet";
    font-size: 0.8vw;
    position: absolute;
    background: black;
    color: white;
    border-radius: 5px;
    width: 10vw;
    bottom: -10px;
} */

.assignment-id {
  float: right;
  font-size: 1.3rem;
  margin-right: 20px;
  margin-top: 5px;
}

/* ================ DETAILS TAB =============*/

.details-tab {
  position: relative;
  display: block;
  overflow: auto;
  background-color: white;
  height: auto;
  padding: 1vh;
}

.bottom-dash-head-table {
  width: 100%;
}

.bottom-dash-head-table th:first-child {
  width: 1%;
  white-space: nowrap;
}

.tab-head {
  position: relative;
  display: inline-block;
  font-size: 1.22vw;
  margin: 20px;
}

.tab-head-dash {
  position: relative;
  top: -5px;
  display: inline-block;
  width: 99%;
  height: 1px;
  background: grey;
}

.assignment-info-table {
  width: 98%;
}

.assignment-info-table td {
  padding: 5px 5px 0px 5px;
  width: calc(100% / 3);
  font-size: 1.4rem;
}

.assignment-info-label {
  margin: 10px 0px 5px 15px;
  font-size: 0.9vw;
  color: grey;
}

.assignment-info-value {
  margin: 2px 0px 5px 15px;
  word-wrap: break-word;
  font-size: 0.9vw;
}

#opps-feedback-btn {
  padding: 0px;
  border: none;
  font-size: 0.9vw;
  background: transparent;
  color: var(--light-blue, #3cc3f2);
  text-decoration: underline;
}

/* ================ OPS FEEDBACK POPUP =============== */
.ops-feedback-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  flex-direction: column;
  row-gap: 20px;
  height: 30vh;
  width: 30vw;
  padding: 15px;
  z-index: 2;
  background: var(--white, #ffffff);
  border-radius: 5px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 4px 2px rgb(117 116 116 / 34%);
}
.ops-feedback-popup-active {
  display: flex;
}

.ops-feedback-popup-head-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ops-feedback-popup-head {
  position: relative;
  font-size: 1.22vw;
}

.ops-feedbacks-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  border: none;
  background: transparent;
  overflow-y: auto;
  height: 100%;
}

.ops-feedbacks-container::-webkit-scrollbar {
  position: absolute;
  width: 6px;
}

.ops-feedbacks-container::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border: 1px solid var(--dark-blue, #112949);
  border-radius: 5px;
}

.ops-feedbacks-container::-webkit-scrollbar-thumb {
  background-color: var(--dark-blue, #112949);
  border-radius: 5px;
}

.ops-feedbacks-version-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.ops-feedback-item-version-head {
  font-weight: bold;
}

.ops-feedback {
  position: relative;
  display: grid;
  row-gap: 5px;
  column-gap: 5px;
  grid-template-rows: min-content min-content min-content;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "user email"
    "quality service"
    "comment comment";
}

.ops-feedback-item {
  position: relative;
  display: flex;
  row-gap: 5px;
  flex-direction: column;
}

#ops-feedback-comment {
  grid-column: 1 / span 2;
}

.ops-feedback-heading {
  display: position;
  color: var(--dark-grey);
}

/* ======= Delivery Plan table ====================*/

.delivery-plan-tab {
  font-size: 1.3vw;
  border-radius: 5px;
  background-color: white;
  padding: 1vh;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: 0px 0px 4px 2px rgba(117, 116, 116, 0.34);
}

.delivery-plan-table {
  position: relative;
  margin: 10px 21px;
  margin-bottom: 10px;
  width: 99%;
  border-radius: 15px;
  table-layout: fixed;
}

.delivery-plan-table-head {
  width: 99%;
  margin: 0px 22px 10px;
  color: grey;
  font-size: 0.9vw;
}

.delivery-plan-table-head th {
  width: 100px;
}

.delivery-plan-table-head th:first-child {
  width: 90px;
}

.delivery-plan-table td {
  width: 100px;
  padding: 5px 0px;
}

.delivery-plan-table td:first-child {
  width: 90px;
  vertical-align: top;
}

.word-count-td {
  font-size: 0.9vw;
}

.bottom-dashboard-value {
  word-wrap: break-word;
  padding: 5px 0px;
  font-size: 0.9vw;
}

/* ======= Delivery Tab ===========================*/

.bottom-tab-head {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-align: center;
}

.delivery-dash {
  width: 97%;
  margin: auto;
}

.bottom-tab-head-item {
  position: relative;
  display: inline-block;
  flex: 1 1 auto;
}

.bottom-tab-head-heading {
  flex: 0 1 auto;
  font-size: 1.22vw;
  margin: 20px;
  margin-bottom: 0px;
}

.bottom-tab-head-dash {
  flex: 10 0 auto;
}

.tab-head-line {
  position: relative;
  display: inline-block;
  width: 99%;
  height: 1px;
  background: grey;
}

.bottom-tab-head-btn {
  flex: 0 1 auto;
  margin-bottom: 0;
  margin: 20px;
  margin-bottom: 10px;
  margin-right: 1.5vw;
}

.deliver-version-btn {
  background: var(--light-blue, #3cc3f2);
  color: white;
  width: 8vw;
  padding: 0.8vh;
  font-size: 0.9vw;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.get-version-key {
  position: relative;
  background: none;
  display: flex;
  align-items: center;
  column-gap: 0.2vw;
  color: var(--orange, #ea7b2c);
  width: 8vw;
  text-align: left;
  font-size: 0.9vw;
  border: none;
  outline: none;
  cursor: pointer;
}

/* .deliver-version-icon {
    height:18%;
     width:18%;
} */

.deliver-version-icon {
  position: relative;
  height: 100%;
  width: 18%;
}

.deliver-btn-div {
  position: relative;
  text-align: left;
}

.deliver-icon-div {
  display: inline-block;
}
.bottom-dashboard-table {
  width: 98%;
  margin: auto;
  margin-bottom: 10px;
}

.bottom-dashboard-table td {
  position: relative;
  vertical-align: top;
  width: 35%;
  /* border: 1px solid black; */
}

.bottom-dashboard-value-heading {
  color: grey;
  font-size: 0.9vw;
  margin-bottom: 5px;
}

.version-delivery-notes {
  text-align: justify;
  width: 15vw;
}

.deliver-btn-td {
  text-align: left;
  padding-right: 10px;
}

.bottom-dashboard-table-label {
  position: relative;
  margin: 10px 0px 5px 10px;
  font-size: 0.9vw;
  color: grey;
}

.bottom-dashboard-table-value {
  position: relative;
  word-wrap: break-word;
  display: block;
  margin: 2px 0px 7px 10px;
}

.delivered-version {
  vertical-align: bottom;
  background-color: white;
}

#deliver-new-file-assignment-id {
  border: none;
  text-align: left;
}

/* ================ Ops Modal ==================*/
.ops-modal-container {
  /* position:absolute;
  height: fit-content;
  width: fit-content;
  background: var(--white);
  text-align: left;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%); */
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 30vw;
  min-height: 10vh;
  max-height: 30vh;
  overflow-y: scroll;
  border-radius: 1vh;
  text-align: left;
  padding: 1vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--white, #ffffff);
  color: var(--dark-blue);
  z-index: 1;
}

.ops-modal-head {
  display: flex;
  justify-content: space-between;
}

.ops-modal-container-head {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: var(--m-font);
  color: var(--label-blue);
}

.ops-encrypted-btn-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ops-modal-container-close-btn {
  position: relative;
  float: right;
  outline: none;
  font-size: var(--s-font);
  z-index: 1;
  cursor: pointer;
  color: var(--light-blue);
  background: transparent;
  border: none;
  outline: none;
  transition: 100ms;
}

.ops-modal-container-close-btn:hover {
  transform: scale(1.2);
}

.ops-modal-btn {
  position: relative;
  min-width: 150px;
  padding: 4px 10px;
  font-size: var(--s-font);
  color: white;
  background: var(--orange, #ea7b2c);
  border-radius: 5px;
  border: 1px solid var(--orange, #ea7b2c);
  outline: none;
  transition: 0.1s;
  cursor: pointer;
}

.ops-modal-btn:hover {
  transform: scale(1.05);
}

/* =========================== SETTINGS PAGES CSS =============================*/

.sidebar-items {
  position: relative;
  float: right;
  width: 100%;
  background: white;
}

.sidebar-items-head {
  position: relative;
  display: block;
  height: 8.1vh;
  background-color: white;
  border-bottom: 0.2vh solid grey;
}

.ops-sort-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: var(--m-font);
  width: fit-content;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--dark-blue, #112949);
}

.add-btn {
  position: absolute;
  padding: 7px 35px;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  font-weight: bold;
  float: right;
  font-size: 1rem;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  background: var(--light-blue, #3cc3f2);
  cursor: pointer;
}

.sidebar-items {
  position: relative;
}

.sidebar-item {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background: white;
  height: 6vh;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid grey;
}

.sidebar-item-head {
  position: relative;
  display: block;
  font-size: 1vw;
  margin-left: 20px;
}

.sidebar-item-logo {
  position: relative;
  height: 3vh;
  width: 3vh;
  border-radius: 1vh;
  margin-left: 2vh;
  font-size: 1.7vw;
  color: var(--dark-blue, #112949);
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.ops-client-item-active {
  background: rgba(60, 195, 242, 0.1);
}

.ops-user-item-active {
  background: rgba(60, 195, 242, 0.1);
  border-bottom: 0.3vh solid grey;
  border-top: 1px solid grey;
}

.client-logo {
  position: relative;
  display: inline;
  width: 100px;
  height: 100px;
}

.client-profile-details {
  position: relative;
  display: flex;
  margin: 20px;
  flex-direction: row;
  vertical-align: top;
}

.client-profile {
  position: relative;
  display: block;
  margin-left: 20px;
}

.client-name {
  font-size: 1.8rem;
}

.client-label {
  position: relative;
  margin: 10px auto;
  left: 10px;
  font-size: 1.2rem;
  color: grey;
}

.client-detail {
  position: relative;
  left: 15px;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.edit-btn {
  position: absolute;
  padding: 7px 50px;
  right: 2%;
  bottom: 10%;
  font-weight: bold;
  float: right;
  font-size: 1.15rem;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  background: var(--light-blue, #3cc3f2);
  cursor: pointer;
}

/* ======================= Client Account Settings ==============*/

/* ============== FORM ================== */

/* .client-account-form-head {
    position: relative;
    font-size: 1.8rem;
}

.client-account-form {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.client-account-form-part {
    position: relative;
    width: calc(100%/3);
}

.ops-profile-image-container{
    position: relative;
    height: fit-content;
    width: fit-content;
}

.ops-profile-image{
    position: relative;
    height: 240px;
    width: 240px;
    object-fit: cover;
    border-radius: 10px;
}
@media only screen and (max-width: 1380px) {
    .ops-profile-image{
        height: 185px;
        width: 185px;
    }
}

.client-account-form-label {
    position: relative;
    display: block;
    font-size: 1.2rem;
    margin: 5px 0px;
}

.client-account-form-input {
    position: relative;
    font-size: 1.2rem;
    display: block;
    padding: 3px;
    border: 2px solid black;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 90%;
    background: white;
}


.client-account-form-logo-label {
    height: 50px;
    width: 50px;
}

.client-account-form-logo-input {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}

.client-profile-logo {
    position: absolute;
    right: 10px;
    top: 150px;
}

.client-profile-logo label {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background: var(--light-blue,#3cc3f2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: transform .2s ease-out;
}

.client-logo-edit {
    font-size: 2rem;
}

.new-client-profile-logo-img {
    position: absolute;
    bottom: -35px;
    left: 10px;
    font-size: 0.85rem;
    color: #555;
}

.client-profile-logo:hover label,
.client-profile-logo:focus label {
    transform: scale(1.02);
}
.client-account-form select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

.client-account-input-select .client-account-form-input{
    width: 100%;
  }
.client-account-input-select{
    position: relative;
    width: 90%;
}

.client-account-input-select .client-number-type-input{
    width: 100%;
  }

.client-account-input-number-select{
    width: 30%;
    display: inline;
    float: left;
  }

.client-account-input-select::after{
    content: '\f078';
    font: normal normal normal 17px/1 FontAwesome;
    position: absolute;
    display: block;
    color: var(--dark-grey);
    top: 1.3vh;
    right: 1.5vh;
    font-size: var(--s-font);
    z-index: 0;
  }

.client-profile-logo:focus+label {
    outline: 1px solid #000;
    outline: -webkit-focus-ring-color auto 2px;
}

.client-account-form-btns {
    position: absolute;
    display: flex;
    right: 1%;
    bottom: 0%;
    flex-direction: column;
}

.client-profile-submit-btn {
    position: relative;
    padding: 7px 50px;
    width: 180px;
    float: right;
    margin-bottom: 20px;
    font-size: var(--m-font);
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    background: var(--light-blue,#3cc3f2);
    cursor: pointer;
}

.client-profile-clear-btn {
    position: relative;
    padding: 7px 50px;
    width: 180px;
    margin-bottom: 10px;
    color: var(--light-blue,#3cc3f2);
    float: right;
    font-size: var(--m-font);
    border: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid var(--light-blue,#3cc3f2);
    background: transparent;
    cursor: pointer;
}

.client-profile-update-password {
    position: relative;
    display: block;
    float: right;
    width: 100%;
    background: white;
}

.client-number-type-input {
    position: relative;
    display: inline;
    float: left;
    width: 20%;
}

.client-number-input {
    position: relative;
    display: inline;
    margin-left: 1%;
    width: 59%;
} */

/* ============== FORM ================== */

/* .client-add-form-head {
    position: relative;
    font-size: 1.22vw;
}

.client-account-form-label {
    color: grey;
    font-size: 0.9vw;
}

.client-account-form-input {
    border: 1px solid grey;
    border-radius: 1.1vh;
    font-size: 0.9vw;
    background: transparent;
    z-index: 2;
}

.client-add-form {
    position: relative;
    display: flex;
    flex-direction: column;
}

.client-add-form-label {
    position: relative;
    font-size: 0.9vw;
    margin: 0px;
}

.client-add-form-input {
    position: relative;
    font-size: 0.9vw;
    padding: 5px;
    border: 1px solid grey;
    border-radius: 4px;
    margin-bottom: 0px;
    width: 50%;
}

.client-add-form-radio {
    position: relative;
    display: inline-block;
    width: 100px;
    font-size: 0.9vw;
    padding: 5px;
    border-radius: 4px;
}

.client-add-form-radio input {
    margin-right: 10px;
}

.client-add-form-btns {
    position: absolute;
    bottom: 0px;
    right: 2%;
    display: flex;
    flex-direction: column;
}

.client-add-submit-btn {
    position: relative;
    padding: 7px 50px;
    width: 180px;
    float: right;
    margin-bottom: 20px;
    font-size: 1.15rem;
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    background: var(--light-blue,#3cc3f2);
    cursor: pointer;
}

.client-add-clear-btn {
    position: relative;
    padding: 7px 50px;
    width: 180px;
    margin-bottom: 20px;
    color: var(--light-blue,#3cc3f2);
    float: right;
    font-size: 1.15rem;
    border: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid var(--light-blue,#3cc3f2);
    background: transparent;
    cursor: pointer;
}

.user-add-pswd-div {
    position: relative;
    width: 50%;
}

.bg-white {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 2%;
} */

input::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.ops-account-logo-img {
  width: 18vh;
  height: 20vh;
  border-radius: 5px;
  object-fit: cover;
}

.ops-account-form-logo-input {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.ops-account-logo label {
  position: absolute;
  bottom: 2px;
  right: -17px;
  border-radius: 0.5vh;
  font-size: 0.8vw;
  padding: 0.5vh;
  color: white;
  background-color: var(--dark-blue, #112949);
  cursor: pointer;
  border: 1px solid white;
}

.client-logo-edit {
  font-size: 1.6vw;
}

.client-account-submit-btn {
  position: relative;
  padding: 0.8vh;
  width: 18vh;
  float: left;
  margin-bottom: 20px;
  font-size: 1vw;
  color: white;
  margin-left: 6.5vw;
  border: none;
  outline: none;
  border-radius: 5px;
  background: var(--orange, #ea7b2c);
  cursor: pointer;
}

.client-account-submit-btn:hover {
  opacity: 0.85;
}

.client-account-submit-btn:disabled {
  opacity: 0.5;
  cursor: default;
}

.client-account-clear-btn {
  position: relative;
  padding: 0.8vh;
  width: 18vh;
  margin-bottom: 3vh;
  margin-left: 6.5vw;
  color: grey;
  font-size: 1vw;
  border: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid grey;
  background: transparent;
  cursor: pointer;
}

.client-account-clear-btn:hover {
  color: var(--orange, #ea7b2c);
  border: 1px solid var(--orange, #ea7b2c);
}

.client-account-form-btns {
  position: absolute;
  display: flex;
  left: 1%;
  bottom: 0.5vh;
  flex-direction: column;
}

.client-account-form-input {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 1vw;
  padding: 0.8vh;
}

.clear-btn {
  position: absolute;
  bottom: 6vh;
  text-decoration: none;
  color: grey;
}

.Collapsible__trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  color: var(--dark-blue, #112949);
  position: relative;
  text-decoration: none;
  font-size: var(--s-font);
  padding: 0.8vh;
  padding-left: 0.7vw;
}

.Collapsible__trigger:after {
  font-family: "FontAwesome";
  content: "\f105";
  position: absolute;
  right: 1.4vh;
  top: 0.4vh;
  font-size: 3vh;
  display: block;
  transition: transform 300ms;
}

.CustomTriggerCSS--open {
  border-bottom: 1px solid grey;
}

.CustomTriggerCSS {
  padding: 0.3vh;
  padding-left: 0.45vw;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(90deg);
}

.Collapsible__trigger.is-closed:after {
  top: -0.2vh;
  right: 1vh;
}
datalist {
  top: 33vh;
  position: absolute;
  height: auto;
  border: 1px solid grey;
  border-top: none;
  background: rgba(252, 252, 252, 1) !important;
  z-index: 4;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 8px 0px;
}

datalist option {
  font-size: 0.9vw;
  cursor: pointer;
  color: black;
  padding: 0.5vh;
}

/* option active styles */
datalist option:hover,
datalist option:focus {
  color: white;
  background-color: rgba(255, 94, 0, 0.952);
  z-index: 10;
}

.ops-select-text-input {
  position: relative;
  background: transparent;
}

.ops-text-input-options {
  position: absolute;
  top: 4vh;
  border: 1px solid var(--dark-grey);
  background: var(--white, #ffffff);
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  z-index: 3;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: 0px 4px 10px 1px rgba(117, 116, 116, 0.34);
}

.ops-text-input-options-show {
  display: flex;
}

.ops-text-input-option {
  padding: 1px 0.8vh;
  font-size: 1vw;
  font-weight: normal;
  color: var(--dark-blue, #112949);
  cursor: default;
}

.ops-text-input-option-active {
  color: white;
}
