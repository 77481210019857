@media only screen and (max-width: 500px) {
  .form-input {
    font-size: var(--s-font);
  }

  .login-input-error {
    font-size: var(--xs-font);
  }

  .form-head {
    font-size: var(--m-font);
  }

  .login-form {
    width: 90%;
  }

  .login-form-container {
    width: 100%;
  }

  .login-img-container {
    width: 0%;
  }

  .login-form a {
    font-size: var(--s-font);
  }

  .form-remember-check {
    font-size: var(--s-font);
  }

  .form-check {
    width: 2.5vh;
    height: 2.5vh;
  }

  .login-btn {
    font-size: var(--s-font);
  }

  .pswd-show-btn {
    font-size: var(--s-font);
  }

  .ops-login-form {
    width: 90%;
  }

  .ops-anchor {
    font-size: var(--s-font);
  }

  .login-footer {
    font-size: var(--xs-font);
  }
}
