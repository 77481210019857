.file-deliver {
  position: relative;
  grid-area: dashboard;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1vh;
  background: var(--background-blue);
  font-size: var(--s-font);
  width: 100%;
  max-height: 100%;
}

.file-deliver-container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
  padding: 0vh;
  border-radius: 1vh;
  background: var(--white);
}
