.details-tab-section {
  display: flex;
  flex-direction: column;
  font-size: var(--s-font);
  padding: 1vh;
  margin: 10px;
  gap: 1vh;
}

.details-tab-head {
  font-size: var(--sm-font);
}

.details-tab-batch-info-container {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

.details-tab-info-label {
  color: var(--dark-grey);
}

.details-tab-batch-assignment-container {
  display: flex;
  flex-direction: row;
  gap: 2vw;
}

/* =========== Services info ================== */

#batch-info-services .details-tab-info-value {
  display: flex;
  justify-content: flex-start;
  gap: 1vh;
}

.details-tab-info-service {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

.details-tab-info-service-head {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 10px;
}

.details-tab-info-service-head-logo {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  font-size: var(--xs-font);
  display: grid;
  justify-content: center;
  align-items: center;
}

.details-tab-info-service-head-logo img {
  height: 2.5vh;
  width: 2.5vh;
}

.details-tab-info-service-value-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 0.5vh;
}

.details-tab-info-service-value-item {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-start;
  column-gap: 3px;
  align-items: center;
}

.details-tab-info-service-value-item-text {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

@media only screen and (max-width: 500px) {
  .details-tab-section {
    height: 35vh;
  }

  #batch-info-services .details-tab-info-value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1vh;
  }

  .details-tab-batch-assignment-container {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
  }
}
