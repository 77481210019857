.login {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  font-size: 1rem;
  overflow: hidden;
}

.login-img-container {
  position: relative;
  float: right;
  width: 70%;
  height: 100vh;
  box-sizing: border-box;
  z-index: -1;
  background: linear-gradient(#010103, #060d17, #112949);
}

.login-img-container img {
  position: relative;
  left: -1%;
  top: -1%;
  height: 102%;
  width: 102%;
}

.login-form-container {
  position: relative;
  display: inline-block;
  width: 30%;
  color: white;
  height: 100vh;
  background-image: linear-gradient(#010103, #060d17, #112949);

  box-sizing: border-box;
}

.brand {
  position: absolute;
  font-size: 2.1vw;
  top: 4vh;
  left: 3vw;
  height: 7.5vh;
  aspect-ratio: 1/1;
  /* width: 13vw; */
  background: transparent;
}

.brand img {
  position: relative;
  height: 100%;
  width: 100%;
  background: transparent;
}

/* ======== LOGIN FORM CSS=========*/

.ops-login-form {
  position: absolute;
  display: block;
  top: 65vh;
  margin: 10px auto;
  height: fit-content;
  left: 50%;
  bottom: 20%;
  transform: translate(-50%, 0%);
  width: 80%;
  z-index: 2;
}

.login-form {
  position: absolute;
  display: block;
  margin: 10px auto;
  left: 50%;
  top: 65vh;
  transform: translateX(-50%);
  width: 80%;
}

.ops-login-btns-section {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

#ops-verify-forget-password-btns-section {
  margin-top: 1vh;
}

.ops-login-btn-part {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ops-login-form-btns {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: var(--s-font);
  text-decoration: none;
  column-gap: 5px;
}

.ops-verify-container {
  position: absolute;
  display: block;
  margin: 10px auto;
  left: 50%;
  bottom: 15vh;
  transform: translateX(-50%);
  width: 90%;
  z-index: 2;
  /* position: absolute;
  top: 40%;
  margin: 10px 35px; */
}

.form-head {
  font-size: 1.5vw;
  color: white;
  /* margin-bottom: 1vh; */
}

.form-input {
  position: relative;
  display: block;
  font-size: 1vw;
  width: 100%;
  padding: 0.8vh;
  border-radius: 5px;
  border: none;
  color: black;
  margin: 1vh auto;
  outline: none;
}

.form-remember-check {
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;
  column-gap: 1vh;
  /* align-items: center; */
  justify-content: center;
  font-size: 1vw;
}
.form-remember-check label {
  padding: 0px;
  margin: 0px;
  height: fit-content;
}

.form-check {
  font-size: 1vw;
  width: 1.8vh;
  height: 1.8vh;
}

.pswd-show-btn {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  right: 0.7vh;
  padding: 0.4vh;
  font-size: var(--m-font);
  background: transparent;
  outline: none;
  border: none;
  color: var(--black, #03080f);
}
.ops-password-input {
  position: relative;
  display: flex;
  align-items: center;
}

.ops-login-btns-container {
  position: relative;
  display: flex;
  vertical-align: center;
  align-items: center;
  margin-top: 1vh;
  height: fit-content;
  flex-direction: row;
  justify-content: space-between;
}

.ops-login-btn {
  position: relative;
}

.login-btn {
  position: relative;
  padding: 0.7vh 4vh;
  font-size: 1vw;
  right: 0;
  /* bottom: -1vh; */
  background: #ea7b2c;
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
}
.login-btn:hover {
  opacity: 0.9;
}
.login-form a {
  font-size: 1.1vw;
}

#ops-update-password {
  padding: 00.7vh 2vh;
}

.ops-form-login-text-btns {
  position: relative;
  display: flex;
  row-gap: 1vh;
  flex-direction: column;
}

.ops-login-form-btns {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: var(--s-font);
  text-decoration: none;
  column-gap: 5px;
}

.ops-anchor {
  position: relative;
  display: inline-block;
  width: fit-content;
  font-size: 1.1vw;
  color: var(--light-blue, #3cc3f2);
  position: relative;
  display: block;
  margin: 2px 0px;
  cursor: pointer;
}

#forget-pswd-btn {
  background: var(--light-blue, #3cc3f2);
  padding: 0.7vh 2vh;
}

.login-input-error {
  font-size: 0.8vw;
  color: red;
}
.login-footer {
  position: absolute;
  bottom: 1vh;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  font-size: 1vw;
  text-align: center;
}

.login-footer a {
  text-decoration: none;
  color: var(--white);
}
