/* ============== OPS PASWWORD CHANGE MODAL =============== */

.ops_modal_password {
  position: absolute;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: rgba(17, 41, 73, 0.7);
}

.ops-change-password-container {
  position: relative;
  display: block;
  float: right;
  top: 7vh;
  width: 71.1vw;
  height: 93vh;
  padding: 1vh;
  background: white;
}

.update-ops-pswd-head {
  font-size: var(--m-font);
  margin-bottom: 15px;
}

/* ============ new form css =================== */
.update-ops-pswd-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2vh;
}

.update-pswd-modal-info {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 100%;
}

.update-pswd-modal-info-input {
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--s-font);
}

.ops-pswd-change-form-input {
  position: relative;
  padding: 0.8vh;
  width: 100%;
  border-radius: 5px;
  background: white;
  border: 1px solid grey;
}

.ops-pswd-change-form-input-show-btn {
  position: absolute;
  margin-left: -1.8vw;
  font-size: var(--s-font);
  color: grey;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.ops-pswd-change-form-label {
  position: relative;
  display: inline-block;
  color: grey;
  width: 100%;
}

#update-pswd-modal-btn-container {
  align-items: flex-end;
}

.ops-pswd-change-form-btn {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  font-size: var(--s-font);
  margin: 0;
  background: white;
  color: grey;
  outline: none;
  border: 1px solid grey;
  cursor: pointer;
}

.ops-pswd-change-cancel-btn {
  background: white;
  color: var(--orange, #ea7b2c);
  outline: none;
  border: 1px solid var(--orange, #ea7b2c);
}

.ops-pswd-change-update-btn {
  background: var(--orange, #ea7b2c);
  color: white;
  border: none;
}

.update-password-error {
  vertical-align: top;
}

.ops-pswd-change-error {
  font-size: var(--xs-font);
  margin-top: 2px;
  /* margin-left: 1vw; */
  width: 90%;
  height: 100%;
  vertical-align: top;
  height: fit-content;
  word-wrap: break-word;
  color: var(--orange, #ea7b2c);
}

/* ===================== IPAD ========================= */
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  .ops_modal_password {
    z-index: 4;
  }

  .ops-change-password-container {
    top: 8vh;
    height: 92vh;
    position: absolute;
    width: 100vw;
    z-index: 5;
  }

  .update-ops-pswd-form {
    display: flex;
    flex-direction: column;
  }

  #update-pswd-modal-btn-container {
    flex-direction: row;
    align-items: flex-end;
  }
}

@media only screen and (max-width: 500px) {
  .ops_modal_password {
    z-index: 4;
  }

  .ops-change-password-container {
    top: 8vh;
    height: 92vh;
    position: absolute;
    width: 100vw;
    z-index: 5;
  }

  .update-ops-pswd-form {
    display: flex;
    flex-direction: column;
  }

  #update-pswd-modal-btn-container {
    flex-direction: row;
    align-items: flex-end;
  }
}

/* ============= Old Tabular form CSS ============== */
/* .update-ops-pswd-form table {
    position: relative;
    width: 95%;
    margin: 10px;
    padding: 0%;
}

.update-ops-pswd-form td {
    width: 20%;
    padding: 0vh;
}

.update-ops-pswd-form tr>td:nth-child(3) {
    width: 20%;
    padding: 0%;
} */

/* .update-ops-pswd-form {
    position: relative;
    display: block;
    font-size: 1.1vw;
    ;
} */

/* .ops-pswd-change-form-label2 {
    position: relative;
    display: inline-block;
    font-size: 0.9vw;
    color: grey;
    width: 100%;
    margin-bottom: 5px;

} */

/* .ops-pswd-change-form-input {
    position: relative;
    display: inline-block;
    font-size: 0.9vw;
    padding: 0.8vh;
    width: 90%;
    border-radius: 5px;
    outline: none;
    border: 1px solid grey;
} */

/*
.ops-pswd-change-form-input-show-btn {
    position: absolute;
    height: 1vh;
    width: 1vw;
    margin-top: 0.9vh;
    margin-left: -1.8vw;
    font-size: 1.1vw;
    color: grey;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
} */
