/* ========= SIDEBAR CSS ========== */

.ops-sidebar {
  position: relative;
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  height: 100vh;
  border-right: 2px solid grey;
  /* background-color: #ebf2f5; */
  background-color: var(--white);
}

.hide-sidebar {
  display: none;
}

.files-history {
  position: relative;
  width: 100%;
  /* background: white; */
  overflow-y: auto;
}

.ops-file-history-empty {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: var(--s-font);
  color: var(--dark-blue, #112949);
  margin: 10px auto;
}

.file-history-head {
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-template-columns: repeat(1, max-content);
  row-gap: 2vh;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  padding: 1vh;
}

.sidebar-head {
  position: relative;
  /* margin: 0.6vw; */
  display: grid;
  grid-template-columns: repeat(2, min-content);
  align-items: center;
  column-gap: 1vh;
  font-size: 1.6vw;
  /* margin-top: 2vh; */
  /* margin-bottom: 1.5vh; */
}

.deliver-btn {
  position: relative;
  padding: 0.8vh 1.5vw;
  color: white;
  float: right;
  border: none;
  outline: none;
  border-radius: 5px;
  text-align: center;
  /* margin: 1.5vh; */
  /* margin-top: 2vh; */
  background: var(--orange, #ea7b2c);
  cursor: pointer;
  text-decoration: none;
}

.deliver-btn:hover {
  opacity: 0.85;
}

.search-sort-form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / span 2;
}

.search-input {
  position: relative;
  padding: 0.8vh;
  width: 100%;
  border-radius: 5px;
  background: white;
  border: 1px solid grey;
}

.search-btn {
  display: flex;
  position: absolute;
  right: 0.6vh;
  font-size: 1.1vw;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-btn svg {
  height: 2vh;
  width: 2vh;
  color: var(--light-blue);
}

.sort-btn {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  right: 5px;
  height: 2vh;
  width: 2vw;
  width: fit-content;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.sort-btn span {
  position: relative;
  display: block;
  font-size: 1vw;
  color: var(--light-blue, #3cc3f2);
}

.sort-btn img {
  position: relative;
  display: inline-block;
  height: 3vh;
  width: 3vw;
}

/* ============= Old Files History ================== */
/*
.assignments {
  position: relative;
  width: 100%;
  background-color: #ebf2f5;
  overflow-y: overlay;
  overflow-x: hidden;
}

.assignments::-webkit-scrollbar {
  position: absolute;
  width: 1px;
  transition: width 300ms;
}

.assignments::-webkit-scrollbar-track {
  background-color: var(--light-grey);
  transition: 300ms;
}

.assignments::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
  transition: 300ms;
}

.assignments:hover::-webkit-scrollbar {
  width: 0.7vh;
}

.assignments:hover::-webkit-scrollbar-thumb {
  background-color: var(--dark-blue,#112949);
}

.ops-file-stages{
position: relative;
display: flex;
flex-direction: row;
column-gap: 5px;
}

.ops-file-stage{
position: relative;
border-radius: 0px 0px 5px 5px;
font-size: var(--xs-font);
height: 40px;
width: 40px;
display: flex;
justify-content: center;
align-items: center;
background: rgba(241, 247, 249, 0.1);
}

.file-type-svg{
width: 80%;
}

.ops-file-stage-active{
background: var(--black);
}

.ops-file-stage-active .file-type-svg{
color: var(--light-blue,#3cc3f2);
}


.sidebar-ops-file {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-template-rows: repeat(2,max-content);
  row-gap: 1vh;
  column-gap: 1vh;
  height: fit-content;
  color: var(--dark-blue,#112949);
  background: white;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid grey;
  padding: 1vh;
  padding-top: 0px;
}

.sidebar-ops-file:hover {
  border-top: 0.3vh solid grey;
  border-bottom: 0.4vh solid grey;
}

.sidebar-ops-file-active {
  border-top: 0.3vh solid grey;
  border-bottom: 0.4vh solid grey;
}

.sidebar-delivered {
  margin-top: 0.5%;
}

.sidebar-file-info {
  position: relative;
  display: inline-block;
  font-size: 0.9vw;
}

.sidebar-file-filename {
  margin-top: 2vh;
  word-wrap: break-word;
  width: 70%;
}

.sidebar-file-word-count {
  margin-top: 0.5%;
}

.sidebar-file-info-head {
  position: relative;
  display: grid;
  grid-column: 1 / span 2;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1vh;
  grid-template-columns: repeat(2,max-content);
}

.sidebar-file-info-head .file-icon {
  width: 100%;
  height: 100%;
  height: 4vh;
  width: 3vh;
}

.sidebar-file-steps {
  height: 20px;
  top: 15%;
  left: 15%;
}

.delivered-time {
  color: rgb(80, 207, 80);
}

.sidebar-collapse {
  border-bottom: 1px solid grey;
  position: relative;
  padding: 1%;
  font-size: 0.9vw;
  z-index: 2;
}

.sidebar-collapse-icon {
  position: absolute;
  right: 0;
  transition: 0.3s;
}

.sidebar-collapse-icon-active {
  transform: rotateZ(-90deg);
}

.ops-dashboard-archive{
  display: block;
  font-weight: 400;
  color: var(--dark-blue,#112949);
  position: relative;
  text-decoration: none;
  font-size: 0.9vw;
  padding: 0.8vh;
  padding-left: 0.7vw;
  text-align: center;
  cursor: pointer;
} */

/* ======================= NEW SIDEBAR FILE HISTORY FROM CLIENT ================ */

/* ============ SIDEBAR FILES ==============*/

.ops-files-history {
  position: relative;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: 300ms;
  -webkit-box-shadow: 0px 4px 4px 0px var(--shadow-color);
  -moz-box-shadow: 0px 4px 4px 0px var(--shadow-color);
  box-shadow: 0px 4px 4px 0px var(--shadow-color);
  z-index: 2;
}

.ops-files-history::-webkit-scrollbar {
  position: absolute;
  overflow-y: overlay;
  display: block;
  width: 2px;
  transition: width 300ms;
}

.ops-files-history::-webkit-scrollbar-track {
  background-color: var(--light-grey);
  transition: 300ms;
}

.ops-files-history::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
  transition: 300ms;
}

.ops-files-history:hover::-webkit-scrollbar-thumb {
  background-color: var(--dark-blue, #112949);
}

.ops-month-container {
  position: relative;
  display: block;
  cursor: pointer;
  overflow: hidden;
  height: auto;
  transition: height 500ms;
  box-sizing: border-box;
}

.ops-month-container-hidden {
  height: 22px;
}

.ops-sidebar-month-head {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  padding: 10px;
  background-color: var(--background-blue, #ebf2f5);
}

.ops-month {
  position: relative;
  font-size: var(--s-font);
  color: var(--dark-blue, #112949);
}

.ops-month-arrow {
  position: relative;
  font-size: var(--m-font);
  color: var(--dark-grey);
  transition: 0.3s;
}

.ops-month-arrow-active {
  transform: rotateZ(-90deg);
}

.ops-file-list {
  position: relative;
  overflow-y: hidden;
  height: fit-content;
  width: 100%;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}

.ops-sidebar-file {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: var(--dark-blue, #112949);
  cursor: pointer;
  border: none;
  /* border-radius: 5px; */
  transition: 100ms;
  box-sizing: border-box;
  overflow: hidden;
}

.ops-sidebar-file:hover {
  border-left: none;
  border-right: none;
}

.ops-sidebar-archive-file-show {
  height: fit-content;
  /* margin: 2px 10px; */
  padding: 10px;
  border: 2px solid var(--light-grey);
  border-left: none;
  border-right: none;
}

.ops-sidebar-file-show {
  height: fit-content;
  /* margin: 2px 10px; */
  padding: 0px 10px 5px 10px;
  border: 2px solid var(--light-grey);
  border-left: none;
  border-right: none;
}

.ops-sidebar-file-active {
  /* background: linear-gradient(90deg, rgba(17, 41, 73, 0.25) 0%, rgba(0,0,0,0.25) 100%); */
  /* backdrop-filter: 0.8; */
  border-color: var(--dark-blue);
}

#ops-dashboard-archive-month {
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.ops-sidebar-archive-file {
  padding: 0px;
}

.ops-sidebar-file-hide {
  height: 0px;
  margin: 0px;
  padding: 0px;
}

.ops-sidebar-file-locked {
  color: var(--light-grey);
  opacity: 0.9;
}

.ops-sidebar-file-lock {
  position: absolute;
  margin: 10px;
  right: 0px;
  color: var(--orange, #ea7b2c);
}

.ops-sidebar-file-info {
  position: relative;
  display: inline-block;
  font-size: var(--s-font);
}

.ops-sidebar-file-details {
  width: calc(50% - 5px);
  margin-right: 5px;
}

.ops-sidebar-file-icon {
  position: relative;
  display: inline-block;
  height: 40px;
  width: 40px;
}

.ops-sidebar-file-icon .ops-file-icon {
  width: 100%;
  height: 100%;
}

.ops-sidebar-file-feedback {
  display: flex;
  align-items: center;
}

.ops-sidebar-file-filename {
  display: flex;
  font-size: var(--s-font);
  align-items: center;
  width: 100%;
}

.ops-file-stages {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  column-gap: 5px;
}

.ops-file-stage {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  font-size: var(--xs-font);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(241, 247, 249, 0.1);
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
}

.file-type-svg {
  width: 60%;
}

.ops-file-stage-active {
  background: rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.ops-file-stage-active .file-type-svg {
  color: var(--light-blue, #3cc3f2);
}

.ops-file-history-empty {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: var(--s-font);
  color: var(--dark-blue);
  margin: 10px auto;
}

.ops-file-history-see-more {
  margin: 1vh 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ops-file-history-see-more-span {
  color: var(--dark-blue);
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: var(--s-font)
}

.ops-empty-dashboard {
  position: relative;
  grid-area: dashboard;
  height: calc(100vh-60px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: auto;
  color: var(--dark-blue);
  /* background: var(--vertical-blue-black-gradient); */
  background: transparent;
}

.ops-empty-dashbaord-text {
  font-size: var(--m-font);
  font-style: italic;
}

@media only screen and (max-width: 500px) {
  /* ================ OPS Sidebar ============== */

  .ops-sidebar {
    position: absolute;
    grid-area: dashboard;
    left: -100vw;
    width: 100vw;
    display: flex;
    flex-direction: column;
    height: 86vh;
    overflow-y: auto;
    transition: 300ms;
    border: none;
  }

  .file-history-head {
    row-gap: 1vh;
  }

  .deliver-btn {
    min-width: 25vw;
  }

  .sidebar-head {
    font-size: var(--sm-font);
  }

  .ops-sort-btn svg {
    width: 2vh;
    height: 2vh;
  }

  .ops-new-file-btn {
    padding: 2px;
    min-width: fit-content;
  }

  .ops-sidebar-show {
    left: 0px;
  }

  .ops-sidebar-mobile {
    z-index: 3;
    overflow: hidden;
  }

  .files-history {
    height: 100%;
  }

  .ops-files-history {
    overflow-y: overlay;
  }

  .sidebar-ops-file {
    height: fit-content;
  }

  .sidebar-file-info {
    font-size: var(--s-font);
  }

  .sidebar-file-icon {
    height: 4vh;
    width: 4vh;
  }

  .ops-file-stage {
    min-width: 10vw;
    padding: 0.8vw;
    width: fit-content;
    font-size: var(--s-font);
  }
}
