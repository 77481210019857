/* ============= Logs Component ========================== */

.ops-logs-container {
  display: flex;
  flex-direction: column;
  padding: 2%;
  row-gap: 2vh;
  height: 100%;
  overflow-y: auto;
}

.ops-log-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: var(--white, #ffffff);
  border-radius: 5px;
  box-shadow: 0px 0px 4px 2px rgb(117 116 116 / 34%);
  height: fit-content;
  max-height: 28vh;
  overflow-y: auto;
}

.ops-log-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
}

.ops-log-items-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-y: auto;
}

.ops-log-item-container {
  position: relative;
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  background: #f1f7f9;
}

.ops-log-item-card {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  font-size: var(--s-font);
  word-break: break-all;
}

.ops-log-item-head {
  display: none;
  color: var(--label-blue, #9cbdeb);
}

.ops-log-item-value {
  display: flex;
  align-items: center;
  font-size: 0.9vw;
}

.ops-access-log-type-logo {
  width: fit-content;
}
.ops-access-log-type-logo img {
  height: 20px;
  width: 20px;
}

.ops-log-msg {
  text-align: center;
  padding: 10px;
  font-style: italic;
  color: var(--dark-blue, #112949);
}

/* ==================== Share Component Style ================== */

.ops-share-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
}

.ops-share-open-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: var(--light-blue, #3cc3f2);
  color: var(--white, #ffffff);
  font-size: var(--ml-font);
}

.ops-shared-users-container-head {
  position: relative;
  display: flex;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0px;
  padding: 0px 10px;
}

.ops-shared-users-container-head-value {
  position: relative;
  color: var(--dark-grey);
  width: 100%;
  font-size: 0.9vw;
}

.ops-shared-users-container-head-value-center {
  text-align: center;
}
/*
  .ops-shared-users-container{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
   */
.ops-shared-user-card {
  position: relative;
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  border-radius: 5px;
  width: 100%;
  background: #f1f7f9;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "user shared-by time-accessed last-downloaded-version last-accessed-on";
}

/* .ops-shared-user-card-info{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    column-gap: 10px;
    align-items: flex-start;
    padding: 10px;
    width: 100%;
    font-size: var(--s-font);
  } */

.ops-share-user {
  grid-area: user;
}

.ops-share-shared-by {
  grid-area: shared-by;
}

.ops-share-time-accessed {
  grid-area: time-accessed;
}

.ops-share-last-downloaded-version {
  grid-area: last-downloaded-version;
}

.ops-share-last-accessed-on {
  grid-area: last-accessed-on;
}

.ops-shared-user-card-info-head {
  display: none;
  color: var(--label-blue, #9cbdeb);
}

.ops-shared-user-card-info-center {
  align-items: center;
}

/* ===================== ACCESS LOG ======================= */
.ops-access-log-item-container {
  align-items: center;
  grid-template-columns: max-content 1fr 0.5fr 0.4fr;
  grid-template-areas: "type-logo string platform date";
}

.ops-access-head {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.ops-access-log-search-container {
  display: flex;
  align-items: center;
  width: 45%;
}

.ops-access-log-search-form {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.ops-access-log-search-input {
  background: transparent;
}

.ops-access-log-search-btn {
  position: absolute;
  display: inline-flex;
  align-items: center;
  right: 10px;
  font-size: var(--m-font);
  background: transparent;
  border: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  color: var(--label-blue, #9cbdeb);
  transition: 150ms;
}

.ops-access-log-search-btn:hover {
  transform: scale(1.05);
}

.ops-access-log-type-logo {
  grid-area: type-logo;
}

.ops-access-log-string {
  grid-area: string;
}

.ops-access-log-platform {
  grid-area: platform;
}

.ops-access-log-date {
  grid-area: date;
}

.ops-blue-access-log {
  color: var(--label-blue, #9cbdeb);
}

@media only screen and (max-width: 500px) {
  /* =================== Logs ==================== */
  .ops-shared-user-card {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 7px;
    backdrop-filter: none;
    row-gap: 5px;
    column-gap: 5px;
    grid-template-areas:
      "user last-accessed-on"
      "shared-by last-downloaded-version ";
  }

  .ops-log-msg {
    font-size: var(--s-font);
  }

  .ops-shared-user-card-info {
    font-size: var(--xs-font);
    align-items: flex-start;
    row-gap: 5px;
    padding: 0px;
  }

  .ops-share-card-head {
    margin-bottom: 1vh;
  }

  .ops-shared-user-card-info-head {
    display: block;
  }

  .ops-share-time-accessed {
    display: none;
  }

  .ops-shared-users-container-head {
    display: none;
  }

  .ops-share-open-modal-btn {
    padding: 5px;
    border-radius: 4px;
    font-size: var(--sm-font);
  }

  .ops-log-item-value {
    font-size: var(--xs-font);
  }

  /* ============= ACCESS LOG =============== */
  .ops-access-log-search-container {
    width: 55%;
  }

  .ops-access-log-item-container {
    row-gap: 2px;
    column-gap: 2px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 0.1fr 0.8fr 1.2fr;
    grid-template-areas:
      "type-logo string string"
      "platform platform date";
  }

  .ops-access-log-search-btn {
    right: 5px;
    height: 15px;
    width: 15px;
  }
}
