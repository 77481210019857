/*======================= New Account CSS =============*/
/* ======================= Client Account Settings ==============*/

.ops-profile-sidebar-items {
  position: relative;
  float: right;
  width: 100%;
}

.ops-profile-sidebar-head {
  position: relative;
  display: block;
  height: fit-content;
  height: 7vh;
  -webkit-box-shadow: 0px 0px 4px 1px var(--shadow-color);
  -moz-box-shadow: 0px 0px 4px 1px var(--shadow-color);
  box-shadow: 0px 0px 4px 1px var(--shadow-color);
  z-index: 1;
  /* background: var(--dark-blue,#112949); */
}

.ops-profile-sidebar-head-top {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto 10px;
  /* color: var(--white,#ffffff); */
}

.ops-profile-sidebar-text {
  position: relative;
  height: fit-content;
  display: inline-block;
  margin: 0px;
  font-size: var(--xl-font);
}

.ops-sidebar-account-item {
  position: relative;
  display: flex;
  flex-direction: row;
  color: var(--dark-blue);
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  /* padding-left: 10px; */
  border-bottom: 1px solid var(--light-grey);
  border-top: 1px solid var(--light-grey);
  margin: 10px 10px;
  border: 1px solid var(--light-grey);
  /* color: white; */
  transition: 100ms;
  /* box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    -moz-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    box-shadow: 0px 0px 4px 1px var(--shadow-color); */
  /* background: rgba( 255, 255, 255, 0.20 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 10.0px );
    -webkit-backdrop-filter: blur( 10.0px ); */
  border-radius: 5px;
}

.ops-sidebar-account-item:hover {
  /* background: rgba( 255, 255, 255, 0.35 ); */
  border-color: var(--dark-blue);
}

.ops-sidebar-account-item-active {
  /* background: rgba( 255, 255, 255, 0.35 );
    border-bottom: 1px solid var(--dark-blue,#112949);
    border-top: 1px solid var(--dark-blue,#112949); */
  border: 2px solid var(--dark-blue);
}

.ops-sidebar-account-item-head {
  position: relative;
  display: block;
  font-size: var(--s-font);
  margin: 10px;
}

.ops-sidebar-account-item-logo {
  position: relative;
  height: var(--m-font);
  width: var(--m-font);
  font-size: var(--l-font);
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

/* ============== FORM ================== */

.ops-profile-section {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* background: var(--vertical-blue-black-gradient); */
  background: var(--white, #ffffff);
}

.ops-profile-form-container {
  position: relative;
  padding: 15px;
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  /* background: rgba( 255, 255, 255, 0.30 );
    backdrop-filter: blur( 10.0px );
    -webkit-backdrop-filter: blur( 10.0px ); */
  border-radius: 10px;
  /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}

.ops-profile-head {
  position: relative;
  /* color: var(--white,#ffffff); */
  font-size: var(--m-font);
}

.ops-profile-head-mobile {
  display: none;
}

.ops-profile-form-head {
  position: relative;
  font-size: 1.8rem;
}

.ops-profile-form {
  position: relative;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  column-gap: 10px;
  margin: 10px 0px;
}

.ops-profile-form-part {
  position: relative;
  width: 100%;
}
/* .ops-profile-form-part:nth-child(4n){
    display: flex;
    text-align: center;
    justify-content: center;
  } */

.ops-form-fields-part {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 40px;
  grid-row-gap: 10px;
}

.ops-profile-field {
  position: relative;
  display: inline-block;
  height: fit-content;
}

.ops-profile-form-label {
  position: relative;
  display: block;
  text-align: left;
  font-size: var(--s-font);
  /* color: var(--label-blue,#9CBDEB); */
  margin-bottom: 10px;
}

.ops-profile-form-input {
  /* position: relative;
    font-size: var(--s-font);
    display: block;
    padding: 7px 10px;
    border: 0.5px solid rgba( 255, 255, 255, 0.4 );
    border-radius: 5px;
    width: 100%;
    color: var(--white,#ffffff);
    outline: none;
    background: rgba(17, 41, 73, 0.5); */
  background: transparent;
  z-index: 2;
}

/* .ops-profile-form-input::-webkit-input-placeholder {
    color: var(--input-grey,#e98742);;
  }
  .ops-profile-form-input::-moz-placeholder {
    color: var(--input-grey,#e98742);;
  }
  .ops-profile-form-input:-ms-input-placeholder {
    color: var(--input-grey,#e98742);;
  }
  .ops-profile-form-input:-moz-placeholder {
    color: var(--input-grey,#e98742);;
  } */

.ops-profile-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ops-profile-input-select .ops-profile-form-input {
  width: 100%;
}

.ops-profile-input-select {
  position: relative;
  width: 90%;
}
.ops-number-type-input::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.ops-profile-input-select .ops-number-type-input {
  width: 100%;
}

.ops-profile-input-number-select {
  width: 29%;
  display: inline;
  float: left;
}

.ops-number-type-input {
  position: relative;
}

.ops-profile-number-input {
  position: relative;
  display: inline;
  margin-left: 2%;
  width: 69%;
}
.ops-profile-input-select::after {
  content: "\f078";
  font: normal normal normal 17px/1 FontAwesome;
  position: absolute;
  display: block;
  color: var(--white, #ffffff);
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: var(--xxs-font);
  z-index: -1;
}

.ops-profile-image-container {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.ops-profile-image {
  position: relative;
  width: 225px;
  height: 225px;
  border-radius: 10px;
  object-fit: cover;
}

.ops-profile-form-logo-label {
  height: 30px;
  width: 30px;
}

.ops-profile-image-edit {
  position: absolute;
  right: -20px;
  bottom: 4px;
}

.ops-profile-image-edit-input {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.ops-profile-image-edit label {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: var(--light-blue, #3cc3f2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.ops-profile-image-edit-pen {
  font-size: var(--m-font);
}

.new-ops-profile-image {
  position: absolute;
  bottom: -35px;
  left: 10px;
  font-size: 0.85rem;
  color: #555;
}

.ops-profile-image-edit:hover label,
.ops-profile-image-edit:focus label {
  transform: scale(1.02);
}

/* Adding an outline to the label on focus */
.ops-profile-image-edit:focus + label {
  outline: 1px solid #000;
  outline: -webkit-focus-ring-color auto 2px;
}

.ops-profile-form-btns {
  position: absolute;
  right: 0px;
  display: flex;
  bottom: 0%;
  flex-direction: column;
  row-gap: 10px;
}

.ops-profile-form-btn {
  position: relative;
  width: 130px;
  float: right;
  color: white;
  background: var(--orange, #ea7b2c);
  transition: 100ms;
  border: 1px solid var(--orange, #ea7b2c);
}

.ops-profile-form-submit-btn {
  background: var(--light-blue, #3cc3f2);
  transition: 100ms;
  border: 1px solid var(--light-blue, #3cc3f2);
}
.ops-profile-form-submit-btn:hover {
  opacity: 0.9;
  border-color: var(--light-blue, #3cc3f2);
}

.ops-profile-form-clear-btn {
  color: var(--light-blue, #3cc3f2);
  border: 1px solid var(--light-blue, #3cc3f2);
  background: var(--white, #ffffff);
  transition: 300ms;
}
/* .ops-profile-form-clear-btn:hover{
    border-color: var(--orange,#ea7b2c);
    color: var(--orange,#ea7b2c);
  } */

.ops-profile-update-password {
  position: relative;
  display: block;
  float: right;
  width: 100%;
  background: white;
}

.ops-number-type-input {
  position: relative;
  display: inline;
  float: left;
  width: 20%;
}

.ops-number-input {
  position: relative;
  display: inline;
  margin-left: 1%;
  width: 69%;
}

.ops-select-text-input {
  position: relative;
  background: transparent;
}

.ops-text-input-options {
  position: absolute;
  top: 35px;
  border: 1px solid var(--dark-grey);
  background: var(--white, #ffffff);
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  z-index: 3;
  -webkit-box-shadow: 0px 2px 4px 0px var(--shadow-color);
  -moz-box-shadow: 0px 2px 4px 0px var(--shadow-color);
  box-shadow: 0px 4px 10px 1px var(--shadow-color);
}

.ops-text-input-options-show {
  display: flex;
}

.ops-text-input-option {
  padding: 1px 10px;
  cursor: default;
}

.ops-text-input-option-active {
  color: white;
}

/* ============= Profile password change form =================== */

.ops-password-form-container {
  position: relative;
  margin: 10px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-auto-rows: auto;
  column-gap: 30px;
  justify-content: start;
  align-items: start;
  row-gap: 10px;
  column-gap: 40px;
  grid-template-areas:
    "old-pswd - -"
    "new-pswd confirm-pswd pswd-btn";
}

.ops-form-grid-item {
  position: relative;
  height: 100%;
}

#ops-old-password-field {
  grid-area: old-pswd;
}

#ops-new-password-field {
  grid-area: new-pswd;
}

#ops-confirm-password-field {
  grid-area: confirm-pswd;
}

#ops-password-btns-container {
  grid-area: pswd-btn;
}

.ops-pswd-input-contianer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

/* .ops-pswd-form-input{
  position: relative;
  width: 100%;
  font-size: var(--s-font);
  display: block;
  padding: 8px 10px;
  border: 1px solid var(--light-grey);
  border-radius: 7px;
  margin: 0px;
  background: white;
  outline: none;
} */

.ops-pswd-change-form-input-show-btn {
  position: absolute;
  display: flex;
  right: 10px;
  justify-content: center;
  align-items: center;
  font-size: var(--s-font);
  color: var(--black, #03080f);
  height: auto;
  width: auto;
  margin: 0px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;
}

.ops-pswd-change-form-input-show-btn-active {
  color: var(--light-grey, grey);
}

/*
.ops-old-password-input{
  position: inline-block;
  height: 55px;
} */

.ops-settings-pswd-change-error {
  font-size: var(--xs-font);
  margin: 4px 2px;
  height: auto;
  height: fit-content;
  word-wrap: break-word;
  color: var(--orange, #ea7b2c);
}

.ops-pswd-form-old-pswd-error {
  margin: 10px 0px;
}

.ops-pswd-change-form-btns {
  display: flex;
  bottom: 0%;
  flex-direction: column;
}

.ops-pswd-change-form-btn {
  position: relative;
  display: inline-block;
  padding: 8px 0px;
  width: 200px;
  margin-top: 10px;
  border-radius: 8px;
  font-weight: bold;
  font-size: var(--s-font);
  background: white;
  color: grey;
  outline: none;
  border: 1px solid grey;
  cursor: pointer;
}
.ops-pswd-change-form-btn:hover {
  border-color: var(--orange, #ea7b2c);
  color: var(--orange, #ea7b2c);
}

.ops-pswd-change-update-btn {
  background: var(--orange, #ea7b2c);
  color: white;
  cursor: pointer;
  border: 1px solid transparent;
}

.ops-pswd-change-update-btn:hover {
  background: var(--light-orange, #e98742);
  color: white;
}

@media only screen and (max-width: 500px) {
  /* ====================== ACCOUNT SECTION ==================== */
  .client-account-form {
    flex-direction: column;
  }

  /* ============= PROFILE ==================*/
  /* ================ PROFILE FORM =============== */
  .ops-profile-sidebar-head {
    background: transparent;
  }

  .ops-profile-sidebar-text {
    font-size: var(--sm-font);
  }

  .ops-profile-form-container {
    padding: 8px 10px;
    padding: 0px;
  }

  .ops-profile-head {
    display: none;
  }

  .ops-profile-head-mobile {
    margin: 5px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    font-size: var(--sm-font);
  }

  .ops-profile-section {
    min-height: 100%;
    height: fit-content;
    padding: 5px 10px;
  }

  .ops-profile-form {
    grid-template-columns: 100%;
    row-gap: 10px;
    grid-template-rows: auto;
    margin: 0px;
  }

  .ops-profile-form-label {
    margin-bottom: 5px;
  }

  /* .ops-profile-form-input{
        padding: 8px;
    } */

  .ops-form-fields-part {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .ops-profile-image-container {
    left: 50%;
    transform: translateX(-50%);
  }

  .ops-profile-image {
    height: 162px;
    width: 162px;
  }

  .ops-profile-image-edit {
    right: -12px;
  }

  .ops-profile-image-edit label {
    height: 25px;
    width: 25px;
  }

  .ops-profile-image-edit-pen {
    font-size: var(--s-font);
  }

  .ops-profile-form-part {
    width: 100%;
    row-gap: 10px;
  }

  .ops-profile-form-btn {
    max-width: 128px;
    /* padding: 10px; */
  }

  .ops-profile-form-btns {
    position: relative;
    flex-direction: row;
    column-gap: 2px;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  /* =============== PASSWORD FORM ======================= */
  .ops-password-form-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "old-pswd"
      "new-pswd"
      "confirm-pswd"
      "pswd-btn";
  }
}
