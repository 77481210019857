/* ================ BATCH STYLING ========================= */

.batch-delivery-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding: 1.5vh;
}

/* ================ Batch Info Container ======================= */

.batch-delivery-info-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  background-color: var(--background-blue);
  padding: 2vh;
  border-radius: 1vh;
  font-size: var(--s-font);
}

.batch-delivery-info-head {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-template-rows: 1fr;
  gap: 1vh;
  align-items: center;
  justify-content: space-between;
}

.batch-delivery-info {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

.batch-delivery-info-label {
  color: var(--dark-grey);
}

/* ================ Batch Delivery form card ===================== */

.batch-delivery-input-section {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.batch-delivery-input-section-head {
  font-size: var(--ml-font);
}

.batch-delivery-card-container {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  gap: 1.5vh;
  padding: 1.5vh;
  border-radius: 1vh;
  min-height: fit-content;
  max-height: 62vh;
  overflow-y: auto;
}

/* ============= BATCH HEAD ======================= */
.batch-delivery-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.batch-delivery-head-container {
  display: flex;
  align-items: center;
  gap: 1vh;
}

.batch-delivery-head-back-btn {
  display: flex;
  justify-self: flex-start;
  padding: 0vh;
  margin: 0px;
}

.batch-delivery-head-text {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  font-size: var(--ml-font);
}

.batch-delivery-head-btn {
  background-color: var(--light-blue);
}

#batch-delivery-cancel-btn {
  background: var(--white);
  color: var(--light-blue);
}

@media only screen and (max-width: 500px) {
  .batch-delivery-container {
    padding: 1vh;
  }

  .batch-delivery-info-container {
    padding: 1vh;
  }

  .batch-delivery-head-text {
    font-size: var(--sm-font);
  }

  .batch-delivery-info-head {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, max-content);
  }

  .batch-delivery-head-container {
    gap: 0.5vh;
  }

  .batch-delivery-input-section-head {
    font-size: var(--sm-font);
  }

  .batch-delivery-card-container {
    height: 48vh;
  }
}
