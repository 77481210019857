.file-deliver-head {
  display: flex;
  gap: 1vh;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5vh;
  box-shadow: 0px 2px 4px 2px rgb(117 116 116 / 34%);
}

.file-deliver-head-text {
  font-size: var(--l-font);
}

.file-deliver-head-steps-container {
  display: flex;
  gap: 0.5vh;
  align-items: center;
  justify-content: space-between;
}

.file-deliver-head-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vh;
  color: var(--dark-grey);
}
.file-deliver-head-step-active {
  color: var(--light-blue);
}

.file-deliver-head-step-line {
  background-color: var(--dark-grey);
  width: 2.5vw;
  height: 2px;
}
.file-deliver-head-step-active .file-deliver-head-step-line {
  background-color: var(--light-blue);
}

@media only screen and (max-width: 500px) {
  .file-deliver-head {
    padding: 1vh;
    flex-direction: column;
    align-items: flex-start;
  }

  .file-deliver-head-text {
    font-size: var(--sm-font);
  }

  .file-deliver-head-steps-container {
    font-size: var(--xs-font);
  }

  .file-deliver-head-step-line {
    width: 16vw;
  }
}
