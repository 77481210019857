/* ================== Top Dashboard =============== */

.top-dashboard {
  position: relative;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  background-color: white;
  font-size: 0.9vw;
  z-index: 1;
  padding: 1.5vh 1vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    "filename filename delivery-id"
    "client-code requester-name delivered"
    "client-charge-code date-received committed-delivery";
  row-gap: 1vh;
  column-gap: 1vh;
}

.top-dashboard-file-name {
  grid-area: filename;
  height: fit-content;
}

.top-dashboard-client-code {
  grid-area: client-code;
}

.top-dashboard-client-charge-code {
  grid-area: client-charge-code;
}

.top-dashboard-delivered {
  grid-area: delivered;
}

.top-dashboard-requester-name {
  grid-area: requester-name;
}

.top-dashboard-date-received {
  grid-area: date-received;
}

.top-dashboard-committed-delivery {
  grid-area: committed-delivery;
}

.top-dashboard-delivery-id {
  grid-area: delivery-id;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 2vh;
  align-items: center;
  height: fit-content;
}

.delivery-id {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 1vh;
  align-items: center;
  font-size: 1vw;
}

.delivery-id-value {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 1vh;
  align-items: center;
  color: var(--light-blue);
}

.delivery-id-value-copy-btn {
  display: flex;
  font-size: 1.2vw;
  cursor: pointer;
}
.delivery-id-value-copy-btn:hover {
  font-size: 1.22vw;
}

.top-dashboard-update-delivery-btn {
  color: white;
  background-color: var(--light-blue);
}

.top-dash-table {
  width: 100%;
}

.top-dash-table td,
th {
  font-weight: normal;
  width: calc(100% / 3);
  text-align: left;
}

.top-dash-table th {
  font-size: 1.6rem;
  padding: 0px 20px 0px 10px;
}

.top-dash-table td {
  font-size: 1.3rem;
  padding: 10px;
  padding-left: 20px;
}

.top-dashboard-label {
  color: grey;
  font-size: 0.9vw;
  margin-bottom: 5px;
}

.top-dashboard-value {
  font-size: 0.9vw;
  display: flex;
  gap: 1vh;
}

.file-logo {
  position: relative;
  display: flex;
  grid-template-columns: repeat(2, min-content);
  align-items: center;
  height: fit-content;
  column-gap: 1vh;
}

.file-logo img {
  display: inline-block;
  width: 2.8vw;
  height: 7vh;
  margin-right: 10px;
}

.filename {
  position: relative;
  display: inline-flex;
  word-wrap: break-word;
  word-break: break-all;
  /* width: 85%; */
  width: fit-content;
  max-width: 30vw;
  vertical-align: top;
  font-size: 1.22vw;
  width: max-content;
}

.file-logo-new {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: fit-content;
  width: 100%;
}

.file-logo-new img {
  display: inline-block;
  width: 1.5vw;
  height: 3vh;
  margin-right: 10px;
}

.filename-new {
  position: relative;
  display: inline-flex;
  word-wrap: break-word;
  width: 85%;
  vertical-align: top;
  font-size: 0.9vw;
}

.filename span {
  font-size: 1.22vw;
  margin: 0 3px;
}

.file-steps {
  display: inline-block;
  width: 100%;
  margin-left: 10px;
  font-size: 1.22vw;
}

.committed-delivery {
  font-size: 0.9vw;
}

@media only screen and (max-width: 500px) {
  /* ============ top dashboard ============ */
  .top-dashboard {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, min-content);
    grid-template-areas:
      "filename filename"
      "delivery-id delivery-id"
      "client-code client-charge-code"
      "delivered requester-name"
      "date-received committed-delivery";
    row-gap: 1.5vh;
  }

  .top-dashboard-file-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
  }

  .top-dashboard-label {
    font-size: var(--s-font);
  }

  .top-dashboard-value {
    font-size: var(--s-font);
  }

  .file-logo {
    align-self: center;
  }

  .file-logo img {
    height: 5vh;
    width: 5vh;
  }

  .filename {
    font-size: var(--sm-font);
    width: fit-content;
    max-width: max-content;
  }

  .top-dashboard-delivery-id {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    column-gap: 0;
  }

  .delivery-id {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: var(--s-font);
  }

  .delivery-id-value-copy-btn {
    font-size: var(--s-font);
  }

  .top-dashboard-update-delivery-btn {
    justify-self: flex-start;
  }

  /* ============== SELECTOR BUTTON ============ */

  .ops-dashboard-selector-btn-container {
    column-gap: 1.5vh;
  }

  .bottom-dashboard-tab-btn {
    font-size: var(--s-font);
    padding: 0.5vw;
  }

  .bottom-dashboard-share-tab-btn {
    font-size: var(--sm-font);
  }

  .bottom-dashboard-sync {
    font-size: var(--s-font);
  }
}
