.file-deliver-container {
  overflow: visible;
}

.batch-delivery-create-form-card {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-template-rows: 1fr;
  gap: 2vh 5vw;
  background-color: var(--background-blue);
  border-radius: 1vh;
  height: fit-content;
  overflow: visible;
}

.batch-delivery-form-input {
  background-color: var(--white);
  border: 0.5px solid var(--light-grey);
  border-radius: 0.5vh;
}

@media only screen and (max-width: 500px) {
  .batch-delivery-create-form-card {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, max-content);
  }
}
