/* ============ SHARING MODAL ================= */

.ops-share-warning-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  width: 30vw;
  font-size: var(--s-font);
  border-radius: 10px;
  top: 50%;
  max-height: 50vh;
  transform: translate(-10%, -50%);
  background: var(--white, #ffffff);
}

.ops-share-warning-modal-head {
  display: flex;
  justify-content: space-between;
  color: var(--label-blue, #9cbdeb);
}

#ops-share-warning-heading {
  color: var(--orange);
}

#ops-share-warning-heading-close-btn {
  color: var(--light-blue, #3cc3f2);
}

.ops-share-warning-modal-text-container {
  display: flex;
  flex-direction: column;
  gap: 0.9vh;
}

.ops-share-warning-modal-para-span-orange {
  display: block;
  color: var(--orange);
}

.ops-share-warning-modal-input-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  height: fit-content;
  max-height: 50vh;
  max-width: 100%;
  overflow: auto;
}

.ops-share-warning-name-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.ops-share-warning-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  max-height: 50px;
  overflow-y: scroll;
}

.ops-share-warning-name-item {
  position: relative;
  padding: 5px;
  background: var(--light-blue, #3cc3f2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
}
.ops-share-warning-name-item-err {
  background: var(--orange, #ea7b2c);
}

.ops-share-warning-remove-btn {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.ops-share-warning-modal-form {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 5px;
}

.ops-share-warning-modal-input {
  position: relative;
  background: transparent;
  color: var(--dark-blue, #112949);
  font-size: var(--s-font);
}

.ops-share-warning-input-contianer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.ops-share-warning-form-input-show-btn {
  position: absolute;
  display: flex;
  right: 10px;
  justify-content: center;
  align-items: center;
  font-size: var(--s-font);
  color: var(--black, #03080f);
  height: auto;
  width: auto;
  margin: 0px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;
}

.ops-share-warning-form-input-show-btn-active {
  color: var(--light-grey, grey);
}

.ops-share-warning-pswd-error {
  font-size: var(--xs-font);
  margin: 4px 2px;
  height: auto;
  height: fit-content;
  word-wrap: break-word;
  color: var(--orange, #ea7b2c);
}

.ops-share-warning-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ops-share-warning-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#ops-share-warning-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

#ops-share-warning-modal-okay-btn {
  background: var(--orange);
}

@media only screen and (max-width: 500px) {
  /* ============ SHARING MODAL ================= */
  .ops-share-warning-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    font-size: var(--s-font);
    transform: translate(0%, -50%);
  }

  .ops-share-warning-modal-head {
    display: flex;
    justify-content: space-between;
  }

  .ops-share-warning-modal-form {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .ops-share-warning-suggestions-container {
    top: 110%;
    left: 0px;
  }
  .ops-share-warning-modal-input {
    padding: 1vh;
  }
}
