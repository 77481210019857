/* ================== Upload Files ============ */

.upload-files-section-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0 0 1vh 1vh;
  z-index: 1;
}

.upload-group-section-container {
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
  height: fit-content;
  max-height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.upload-section-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.4vh;
}

.upload-section-group-id {
  align-self: center;
  font-size: var(--s-font);
  font-style: italic;
  font-weight: bold;
}

.upload-section {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.1vh;
}

.upload-section-head {
  position: relative;
  display: flex;
  font-size: var(--s-font);
  font-weight: bold;
}

.upload-section-files {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.7vh;
}

.upload-section-file-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2vh;
}

.upload-section-file-name {
  width: 100%;
  word-break: break-all;
  font-size: var(--s-font);
}

.upload-section-file-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vh;
}

.upload-section-file-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: var(--s-font);
}

.upload-section-file-btn-inqueue {
  color: var(--light-grey);
}

.upload-section-file-btn-retry {
  color: var(--dark-grey);
}

.upload-section-file-btn-failed {
  color: red;
}

.upload-section-file-btn-reject {
  color: var(--light-grey);
}

.upload-section-file-btn-success {
  color: var(--light-green);
}

.upload-section-file-btn-uploading {
  color: var(--light-blue);
  animation: rotation 2s infinite linear;
}

/* ======== TABLET ========= */
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
}

/* ============== MOBILE ================ */

@media only screen and (max-width: 500px) {
}
