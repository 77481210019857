/* ============ Suggested Input multi select ============= */
/* .basic-multi-select{
} */

.select__control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vh;
  width: 100%;
  background-color: var(--white);
  outline: none;
  cursor: text;
  color: var(--dark-blue, #112949);
  border: 0.5px solid var(--light-grey);
  border-radius: 6px;
  font-size: var(--s-font);
}

.select__menu {
  width: 100%;
  color: var(--dark-blue);
  background: var(--background-blue);
  padding: 0px;
  margin-top: 2px;
}

.select__menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  background: var(--background-blue);
  padding: 0px;
  max-height: 10vh;
  overflow-y: auto;
  border-radius: 5px;
}

.select__value-container {
  display: flex;
  gap: 0.5vh;
  max-height: 8vh;
  overflow-y: auto !important;
}

.select__multi-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--dark-blue);
  background: var(--light-blue);
  background-color: var(--light-blue);
  border-radius: 5px;
  overflow: hidden;
  padding: 2px;
}

.select__multi-value__label {
  display: flex;
  align-items: center;
  color: var(--white) !important;
}

.select__clear-indicator {
  display: none !important;
}
