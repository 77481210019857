@font-face {
  font-family: "Louis-Geroge-Cafe";
  src: local("Louis-Geroge-Cafe"),
    url("./fonts/Louis-George-Cafe.ttf") format("truetype");
  font-weight: normal;
}

:root {
  --dark-blue: #112949;
  --light-blue: #3cc3f2;
  --label-blue: #9cbdeb;
  --form-background-blue: #f1f7f9;
  --input-grey: #bbbbbb;
  --black: #03080f;
  --light-black: #3d3d3d;
  --white: #ffffff;
  --orange: #ea7b2c;
  --light-orange: #e98742;
  --light-grey: #bdbdbd;
  --dark-grey: #8a8888;
  --background-blue: #ebf2f5;
  --light-green: rgb(0, 255, 0);
  --vertical-blue-black-gradient: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(17, 41, 73, 1) 100%
  );
  --horizontal-blue-black-gradient: linear-gradient(
    270deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(17, 41, 73, 1) 100%
  );
  --shadow-color: rgba(17, 41, 73, 0.5); /*rgba(117, 116, 116, 0.34)*/
  --dark-shadow-color: rgba(0, 0, 0, 0.1);
  --form-drop-shadow-color: #00000029;
  --modal-color: rgba(0, 0, 0, 0.5);
  --xxs-font: 0.4vw;
  --xs-font: 0.6vw;
  --s-font: 0.85vw;
  --sm-font: 0.95vw;
  --m-font: 1vw;
  --ml-font: 1.2vw;
  --l-font: 1.4vw;
  --xl-font: 1.6vw;
  --xxl-font: 2vw;
  --xxxl-font: 2.2vw;
}
/* @media only screen and (max-width: 1300px) {
  :root {
    --xxs-font: 0.8vw;
    --xs-font: 0.9vw;
    --s-font: 1vw;
    --m-font: 1.2vw;
    --l-font: 1.3vw;
    --xl-font: 1.4vw;
    --xxl-font: 1.5vw;
  }
} */
@media only screen and (max-width: 500px) {
  :root {
    /* --xxs-font: 10px;
    --xs-font: 12px;
    --s-font: 14px;
    --sm-font: 16px;
    --m-font: 18px;
    --ml-font: 20px;
    --l-font: 22px;
    --xl-font: 24px;
    --xxl-font: 28px;
    --xxxl-font: 32px */
    --xxs-font: 2vw;
    --xs-font: 3vw;
    --s-font: 4vw;
    --sm-font: 5vw;
    --m-font: 6vw;
    --ml-font: 7vw;
    --l-font: 8vw;
    --xl-font: 9vw;
    --xxl-font: 10vw;
    --xxxl-font: 11vw;
  }
}
/* Font sizes for IPAD and IPAD Pro*/
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  :root {
    --xxs-font: 1vw;
    --xs-font: 1.5vw;
    --s-font: 2.5vw;
    --sm-font: 3vw;
    --m-font: 3.5vw;
    --ml-font: 4vw;
    --l-font: 4.5vw;
    --xl-font: 5vw;
    --xxl-font: 6vw;
    --xxxl-font: 7vw;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Louis-Geroge-Cafe";
}

@media only screen and (min-width: 1150px) {
  ::-webkit-scrollbar {
    position: absolute;
    width: 5px;
    border-radius: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    border: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--light-grey);
  }
}

/* ================= ANIMATIONS ============= */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes bounce-obj {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce-shadow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

#root {
  position: relative;
  display: block;
  height: 100vh;
  width: 100vw;
}

body::-webkit-scrollbar {
  position: absolute;
  display: none;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--light-grey);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--light-grey);
}

button {
  cursor: pointer;
}

ol {
  list-style-position: inside;
}

ul {
  list-style-position: inside;
}

/* #### For removing arrow spinners in number fields ### */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* ======== CLient General CSS ============ */

.client-btns {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: var(--s-font);
  color: white;
  min-width: 150px;
}
.client-btns:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 1300px) {
  .client-btns {
    padding: 5px 14px;
    min-width: 120px;
  }
}
@media only screen and (max-width: 500px) {
  .client-btns {
    padding: 5px 20px;
    min-width: 120px;
  }
}

.client-input {
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--s-font);
  width: 100%;
  padding: 7px 10px;
  border-radius: 6px;
  border: none;
  outline: none;
}
@media only screen and (max-width: 1300px) {
  .client-input {
    padding: 7px;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .client-input {
    padding: 5px;
    border-radius: 5px;
  }
}
.client-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--input-grey, #e98742);
  font-style: italic;
}

.client-dashboard-input {
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--s-font);
  width: 100%;
  padding: 7px 10px;
  border-radius: 6px;
  border: none;
  outline: none;
  background: rgba(17, 41, 73, 0.4);
  color: var(--white, #ffffff);
  border: 0.5px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 1300px) {
  .client-dashboard-input {
    padding: 5px;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .client-dashboard-input {
    padding: 5px;
    border-radius: 5px;
  }
}
.client-dashboard-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-dashboard-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-dashboard-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-dashboard-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--input-grey, #e98742);
  font-style: italic;
}

/* Lazy load image  */
.lazy-img {
  filter: blur(3px);
  transition: 300ms;
}

.show-img {
  filter: none;
}

/* Hoverable tooltip  */
/* .hover-tooltip {
  text-decoration:none;
  position:relative;
}
.hover-tooltip .tooltip-container {
  display:none;
  position:fixed;
  background-color: var(--black,#03080F);
  color: var(--white,#ffffff);
  border-radius: 2px;
  padding: 4px;
  overflow:hidden;
  font-size: var(--s-font);
  z-index: 2;
} */
.tooltip-container {
  display: none;
  position: fixed;
  background-color: var(--black, #03080f);
  color: var(--white, #ffffff);
  border-radius: 2px;
  padding: 4px;
  overflow: hidden;
  font-size: var(--s-font);
  z-index: 2;
}
/* .tooltip-container-hover {
  display:flex;
  flex-direction: column;
} */
@media only screen and (min-width: 1000px) {
  /* .hover-tooltip:hover .tooltip-container {
    display:block;
  } */
  .tooltip-container-hover {
    display: flex;
    flex-direction: column;
  }
}

/* ============ Card Output =============== */
.card-layout {
  position: relative;
  display: block;
  overflow: auto;
  background-color: white;
  height: auto;
  padding: 1vh;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 2px rgb(117 116 116 / 34%);
}

/* ==============Rating Emoji ================== */
.client-feedback-rating-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-feedback-rating-container span {
  display: flex;
  align-items: center;
  justify-content: center;
}
