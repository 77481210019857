/* ========== TOPNAV CSS ===============*/

.ops-topnav {
  position: relative;
  grid-area: topnav;
  background: var(--white, #ffffff);
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  -moz-box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  box-shadow: 0px 2px 4px 0px rgba(117, 116, 116, 0.34);
  z-index: 2;
  padding: 0.8vh;
}

.ops-topnav-brand {
  display: none;
}

.ez-logo {
  display: inline-block;
  height: 6vh;
  width: 6vh;
  margin: auto;
}

.ez-logo img {
  position: relative;
  height: 100%;
  width: 100%;
}

.ops-user {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, max-content);
  justify-content: space-between;
  vertical-align: middle;
  align-content: center;
  column-gap: 1vh;
  color: white;
  height: 6vh;
  right: 10px;
  min-width: 8vw;
  border-radius: 0.6vh;
  padding: 0.7vh;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 300ms;
}

.ops-user:hover {
  border: 1px solid var(--light-grey);
}

.user-img {
  position: relative;
  display: inline-block;
  height: 5vh;
  width: 5vh;
  border-radius: 0.5vh;
  object-fit: cover;
}

.ops-user-name {
  position: relative;
  display: inline-block;
  height: fit-content;
  color: var(--dark-blue, #112949) !important;
  top: 50%;
  font-size: 1.1vw;
  transform: translateY(-50%);
  transition: 300ms;
}

.ops-logout-box {
  position: absolute;
  display: none;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  min-width: 8vw;
  right: 10px;
  top: 0.6vh;
  background: transparent;
  z-index: 2;
  border-radius: 0.7vh;
  overflow: hidden;
  border: 1px solid var(--light-grey);
}

.ops-logout-btns {
  position: relative;
  display: flex;
  justify-content: left;
  vertical-align: middle;
  align-content: center;
  flex-direction: row;
  width: 100%;
  background: white;
  border: none;
  outline: none;
  font-size: 1.1vw;
  z-index: 2;
  padding-left: 0.7vh;
  padding-right: 0.7vh;
  cursor: pointer;
  width: auto;
  border-top: 1px solid var(--light-grey);
}

.ops-logout-btns1 {
  position: relative;
  display: flex;
  justify-content: left;
  vertical-align: middle;
  align-content: center;
  flex-direction: row;
  width: 100%;
  background: white;
  border: none;
  color: var(--dark-blue, #112949);
  outline: none;
  font-size: 1.1vw;
  z-index: 2;
  padding: 0.7vh;
  text-align: right;
  cursor: pointer;
  border-top: 1px solid var(--light-grey);
}

.ops-logout-btns1:hover {
  background-color: #ebf2f5;
}

#ops-user-name-btn {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, max-content);
  justify-content: space-between;
  column-gap: 1vh;
  vertical-align: middle;
  align-content: center;
  height: 5.6vh;
  border-top: none;
}

@media only screen and (max-width: 500px) {
  /* ================ OPS TOPNAV ============== */
  .ops-topnav {
    justify-content: flex-start;
    z-index: 4;
  }

  .ops-topnav-brand {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 1vh 0px;
    border-radius: 5px;
    color: white;
    outline: none;
    transition: 300ms;
    box-sizing: border-box;
  }

  .ops-logout-box {
    top: 1vh;
  }

  #ops-user-name-btn {
    height: 5.8vh;
  }

  .ops-logout-btns1 {
    font-size: var(--s-font);
  }

  .ops-user-name {
    font-size: var(--s-font);
  }
}
