.upload-share-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  border-radius: 10px;
  margin: 1vh 0px;
  /* background: var(--white,#ffffff); */
}

.upload-share-modal-input-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  height: fit-content;
  max-height: 50vh;
  max-width: 100%;
  overflow: auto;
}

.upload-share-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
}

.upload-share-name-item {
  position: relative;
  padding: 5px;
  background: var(--light-blue);
  color: var(--white);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
}
.upload-share-name-item-err {
  background: var(--orange, #ea7b2c);
}

.upload-share-remove-btn {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.upload-share-modal-form {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 5px;
}

.upload-share-modal-input {
  position: relative;
  background: var(--white);
  color: var(--dark-blue, #112949);
  font-size: var(--s-font);
  border-color: var(--light-grey);
}

.upload-share-suggestions-container {
  position: absolute;
  padding: 5px 0px;
  /* margin: 5px; */
  background: var(--white);
  border-radius: 5px;
  color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  max-height: 100px;
  max-width: 90%;
  overflow: auto;
  z-index: 1;
  top: 110%;
  left: 10px;
  /* transform: translate(50%,50%); */
}

.upload-share-error {
  position: relative;
  margin-top: 0px;
  margin-left: 10px;
  text-align: start;
}

.upload-share-suggestion-item {
  width: 100%;
  text-align: left;
  padding: 5px;
  cursor: pointer;
}

.upload-share-suggestion-item:hover {
  background: rgba(60, 195, 242, 0.5);
}

.upload-share-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.upload-share-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#upload-share-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

@media only screen and (max-width: 500px) {
  /* ============ SHARING MODAL ================= */
  .upload-shared-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    font-size: var(--s-font);
    transform: translate(0%, -50%);
  }

  .upload-share-modal-form {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .upload-share-suggestions-container {
    top: 110%;
    left: 0px;
  }
  .ops-modal-container-head {
    font-size: var(--sm-font);
  }

  .ops-modal-head {
    height: min-content;
  }

  .ops-modal-container-close-btn {
    font-size: var(--sm-font);
  }

  .upload-share-modal-input {
    padding: 1vh;
  }
}
