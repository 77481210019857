/* ===================== History Tab ================== */

.history-tab-section {
  position: relative;
  margin: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: min-content;
  row-gap: 20px;
  background: transparent;
  overflow-y: auto;
}

.history-tabs-container {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  color: var(--dark-blue);
  row-gap: 15px;
}

.version-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  font-size: var(--s-font);
  box-shadow: none;
  gap: 0.5vh;
}

.version-card-head {
  position: relative;
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(2, max-content) repeat(2, 20vw) max-content;
  justify-content: space-between;
  column-gap: 40px;
  align-items: center;
  /* padding: 10px; */
  cursor: pointer;
}
.version-card-head .version-card-info {
  grid-template-columns: auto;
}

.version-card-info {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  column-gap: 0.5vw;
  row-gap: 0.5vh;
}

.version-card-info-head {
  display: flex;
  justify-content: space-between;
}

.version-card-question {
  position: relative;
}

.version-card-content {
  position: relative;
  display: grid;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  row-gap: 1.2vh;
  height: 0px;
  overflow: hidden;
  /* padding: 0px 10px; */
  transition: 300ms;
}

.version-card-content-show {
  height: fit-content;
  padding-bottom: 10px;
}

.version-card-content .version-card-info {
  grid-auto-flow: row;
}

.version-card-info-label {
  color: var(--dark-grey);
}

.version-card-info-value {
  display: grid;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  row-gap: 0.5vh;
}

.version-card-info-value span {
  word-wrap: break-word;
}

.version-card-content .delivery-dates {
  display: none;
}

/* .version-card-delivery-notes{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    justify-content: flex-start;
    grid-gap: 0.5vh;
} */

.version-card-delivery-notes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5vh;
}

.version-card-delivery-notes-file-name {
  color: var(--dark-grey);
}

.version-card-delivery-note {
  word-break: break-all;
}

.file-info-file-download-err-text {
  color: #e98742;
}

/* ####################### Tablet ################################## */

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  /* ====================== FAQs ===================== */

  .history-tab-section {
    row-gap: 10px;
    height: calc(100vh - 50px - 50px);
  }

  .version-card-head {
    column-gap: 10px;
    grid-template-columns: repeat(3, max-content);
    justify-content: space-between;
  }

  .version-card-head .delivery-dates {
    display: none;
  }

  .version-card-content .delivery-dates {
    display: grid;
  }
}

/* ####################### Mobile ################################## */

@media only screen and (max-width: 500px) {
  /* ====================== FAQs ===================== */

  .history-tab-section {
    row-gap: 10px;
    height: calc(100vh - 50px - 50px);
  }

  .version-card-head {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    grid-template-columns: repeat(3, max-content);
    justify-content: space-between;
  }

  .version-card-head .delivery-dates {
    display: none;
  }

  .version-card-content .delivery-dates {
    display: grid;
  }
}
