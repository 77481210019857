.file-deliver {
  overflow-y: auto;
}

.batch-delivery-container {
  max-height: 80vh;
}

.batch-delivery-upload-info-container {
  position: relative;
  height: 50%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, max-content);
  grid-template-areas:
    "batch-info-details batch-info-details"
    "batch-info-services batch-info-services"
    "batch-info-input batch-info-output"
    "batch-info-external-links batch-info-external-links"
    "batch-info-notes batch-info-notes";
  gap: 1vh;
  padding: 1vh;
  border-radius: 0.7vh;
  background: var(--background-blue);
}

.batch-delivery-upload-info {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

.batch-delivery-upload-info-label {
  color: var(--dark-grey);
}

.batch-delivery-upload-info-value {
  color: var(--dark-blue);
}

.batch-delivery-upload-info-value-error {
  color: var(--orange);
}

#batch-info-details {
  grid-area: batch-info-details;
  display: flex;
  flex-direction: row;
  gap: 1vh;
  align-items: center;
  justify-content: space-between;
}

#batch-info-services {
  grid-area: batch-info-services;
}

#batch-info-input {
  grid-area: batch-info-input;
}

#batch-info-input .batch-delivery-upload-info-value {
  display: flex;
  flex-direction: column;
  gap: 0.3vh;
}

#batch-info-output {
  grid-area: batch-info-output;
}

#batch-info-output .batch-delivery-upload-info-value {
  display: flex;
  flex-direction: column;
  gap: 0.3vh;
}

#batch-info-notes {
  grid-area: batch-info-notes;
}

#batch-info-external-links {
  grid-area: batch-info-external-links;
}

/* =========== Upload File ==================== */

.upload-file-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2vh;
}

.upload-file-name {
  width: 100%;
  word-break: break-all;
  font-size: var(--s-font);
}

.upload-file-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vh;
}

.upload-file-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: var(--s-font);
}

.upload-file-btn-inqueue {
  color: var(--light-grey);
}

.upload-file-btn-retry {
  color: var(--dark-grey);
}

.upload-file-btn-failed {
  color: red;
}

.upload-file-btn-reject {
  color: var(--light-grey);
}

.upload-file-btn-success {
  color: var(--light-green);
}

.upload-file-btn-uploading {
  color: var(--light-blue);
  animation: rotation 2s infinite linear;
}

/* =========== Services info ================== */

#batch-info-services .batch-delivery-upload-info-value {
  display: flex;
  justify-content: space-between;
  gap: 1vh;
}

.batch-delivery-upload-info-service-head {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 10px;
}

.batch-delivery-upload-info-service-head-logo {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  font-size: var(--xs-font);
  display: grid;
  justify-content: center;
  align-items: center;
}

.batch-delivery-upload-info-service-head-logo img {
  height: 2.5vh;
  width: 2.5vh;
}

.batch-delivery-upload-info-service-value-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 0.5vh;
}

.batch-delivery-upload-info-service-value-item {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-start;
  column-gap: 3px;
  align-items: center;
}

.batch-delivery-upload-info-service-value-item-text {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

/* ================= Share Pannel ========================== */
.batch-delivery-upload-share-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2vh 5vw;
  background-color: var(--background-blue);
  border-radius: 1vh;
  height: fit-content;
  overflow: visible;
  padding: 1vh;
}

@media only screen and (max-width: 500px) {
  .batch-delivery-container {
    max-height: 70vh;
  }

  .batch-delivery-upload-info-container {
    position: relative;
    height: 50%;
    overflow-y: auto;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, max-content);
    grid-template-areas:
      "batch-info-details"
      "batch-info-services"
      "batch-info-input"
      "batch-info-output"
      "batch-info-external-links"
      "batch-info-notes";
  }

  #batch-info-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, max-content);
  }

  #batch-info-services .batch-delivery-upload-info-value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1vh;
  }

  /* ============= SHARE ================= */
  .batch-delivery-upload-share-card {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
