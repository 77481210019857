/* ===================== Delivery Tab ================== */

.delivery-tab-section {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 1.5vh;
  margin: 10px;
  padding: 10px;
  overflow-y: auto;
  font-size: var(--s-font);
  border-radius: 10px;
  box-shadow: none;
}

/* ======================== FILE INFO SECTION =================== */
.file-info-section {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 1vh;
}

.file-info-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--m-font);
  color: var(--dark-grey);
}

.file-info-item {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 0.8vh;
}

/* .file-info-item-head{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content; */
/* grid-template-columns: 1fr; */
/* grid-template-columns: repeat(2,max-content); */
/* justify-content: space-between;
    row-gap: 1vh;
    font-size: var(--sm-font);
    color: var(--dark-grey);
} */

.file-info-item-head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1vh;
  justify-content: space-between;
  align-items: center;
  font-size: var(--sm-font);
  color: var(--dark-grey);
}

#file-info-delivery-notes .file-info-item-value {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  gap: 0.5vh;
}

/* .delivery-notes{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(max-content, 50vw);
    justify-content: flex-start;
    gap: 0.5vh;
} */

.delivery-notes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5vh;
  min-width: 50%;
}

.delivery-notes-file-name {
  color: var(--dark-grey);
  word-break: break-all;
  max-width: 50%;
}

.delivery-note {
  word-break: break-all;
}

#file-info-files-list {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 0.8vh;
}

#file-info-files-container {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 0.8vh;
}

/* .file-info-file{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-columns: 1fr;
    row-gap: 1vh;
} */
.file-info-file {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1vh;
  justify-content: space-between;
  align-items: center;
}

.file-info-file-name {
  display: flex;
  flex-direction: row;
  gap: 0.5vh;
  align-items: center;
  max-width: 50%;
  word-break: break-all;
}

.file-info-file-download {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  align-items: center;
  column-gap: 0.8vh;
}
.file-info-file-download .file-info-file-download-btn {
  min-width: 40px;
}

.file-info-file-download-btn {
  color: var(--light-blue);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 0.3vh;
  align-items: center;
  justify-content: center;
  font-size: var(--s-font);
  font-weight: normal;
  cursor: pointer;
}

.file-info-file-download-btn svg {
  font-size: 1.5vh;
}

/* ======================= FEEDBACK SECTION ======================= */

/* .ops-feedback-input{
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-row-gap: 10px;
    grid-template-areas:
          "ops-quality-feedback ops-comment blank"
          "ops-service-feedback ops-comment ops-submit-btn"
    ;
  } */

.ops-feedback-input {
  position: relative;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-template-areas:
    "ops-quality-feedback ops-service-feedback"
    "ops-message-feedback ops-message-feedback";
}

.ops-feedback-question {
  /* width: 500px; */
  display: flex;
  row-gap: 10px;
  flex-direction: column;
}

#ops-quality-feeback {
  grid-area: ops-quality-feedback;
}

#ops-service-feeback {
  grid-area: ops-service-feedback;
}

.ops-message-feedback {
  grid-area: ops-message-feedback;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#ops-feedback-comment {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ops-feedback-submit-btn {
  position: relative;
  color: var(--light-blue, #3cc3f2);
  background-color: transparent;
  font-size: var(--l-font);
  cursor: pointer;
}
.ops-feedback-submit-btn:hover {
  opacity: 0.9;
}

.ops-feedback-label {
  position: relative;
  display: block;
  min-width: 180px;
  font-size: var(--s-font);
  color: var(--label-blue, #9cbdeb);
  width: fit-content;
  /* margin-bottom: 10px; */
}

.ops-feedback-rating {
  position: relative;
  display: flex;
  column-gap: 30px;
  height: fit-content;
}

.ops-feedback-emoji {
  height: 25px;
  width: 25px;
}

.ops-feedback-rating-container {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 30px;
}
.ops-feedback-rating-container span {
  display: flex;
}

.ops-feedback-text {
  position: relative;
  display: inline;
  margin: auto 0px;
  font-size: var(--s-font);
}

.feedbcak-small-label {
  width: 110px;
}

#commentsFeedback {
  position: relative;
  font-size: var(--s-font);
  background: var(--background-blue);
  width: 100%;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  color: var(--dark-blue);
  border: 0.5px solid rgba(255, 255, 255, 0.4);
}

.ops-profile-form-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--input-grey, #e98742);
}
.ops-profile-form-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--input-grey, #e98742);
}
.ops-profile-form-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--input-grey, #e98742);
}
.ops-profile-form-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--input-grey, #e98742);
}

#comments-ops-feedback-label {
  vertical-align: top;
}

#ops-feedback-sbmt-td {
  vertical-align: top;
}

.rating-container {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  column-gap: 10px;
}

.rating-label {
  display: inline-block;
  height: 25px;
  width: 25px;
}

.rating-label input[type="radio"] {
  display: none;
}

.ops-feedback-rating-error {
  position: relative;
  width: fit-content;
  font-size: var(--s-font);
  height: fit-content;
  text-align: start;
  color: red;
  word-wrap: break-word;
}

.rating-star {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  color: #ebebeb;
}

#overallFeedback {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 100px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #1a9356;
}

.ops-feedback-comment-div {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: justify;
  font-size: var(--s-font);
  justify-content: center;
  margin-right: 10px;
}

/* ============== COMMENT SECTION ================= */
.comments-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  padding: 10px;
  font-size: var(--s-font);
  border-radius: 10px;
  height: 30vh;
  overflow-y: auto;
  background-color: var(--background-blue);
}

.comment-msg {
  display: grid;
  gap: 0.4vh;
  justify-content: space-between;
  align-items: center;
  max-width: 60%;
  min-width: 40%;
  padding: 1vh;
  border-radius: 0.5vh;
  background-color: var(--white);
}

.comment-msg-user {
  grid-area: comment-msg-user;
  color: var(--dark-grey);
}

.comment-msg-date {
  grid-area: comment-msg-date;
  color: var(--dark-grey);
  justify-self: flex-end;
}

.comment-msg-feedback-file {
  grid-area: comment-msg-feedback-file;
  color: var(--dark-grey);
}

.comment-msg-chat-text {
  grid-area: comment-msg-chat-text;
}

/* ====== Unknown Message Type ==== */
/* .comment-msg-unknown{

} */

/* ==== Feedback Comment ========= */
.comment-msg-feedback {
  align-self: flex-start;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    "comment-msg-user comment-msg-date"
    "comment-msg-feedback-file comment-msg-feedback-file"
    "comment-msg-feedback-container comment-msg-feedback-container";
}

.comment-msg-feedback .comment-msg-feedback-container {
  grid-area: comment-msg-feedback-container;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.comment-feedback-quality {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1vh;
}

.comment-feedback-quality-label {
  display: inline-block;
  height: fit-content;
}

/* ===== Chat Messages ==== */
.comment-msg-chat {
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    "comment-msg-user comment-msg-date"
    "comment-msg-feedback-file comment-msg-feedback-file"
    "comment-msg-chat-text comment-msg-chat-text";
}

/* ===== Sent Comment ===== */
.comment-msg-sent {
  align-self: flex-start;
}

/* ===== Receive Comment ===== */
.comment-msg-receive {
  align-self: flex-end;
}

/* ===== Notification Comment ===== */
.comment-msg-notification {
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  grid-template-areas: "comment-msg-chat-text comment-msg-date";
}

/* ================ 720p screen =================== */

@media only screen and (max-width: 1460px) {
  /* ================= Feedback Form ===============*/
  .ops-feedback-rating-container {
    column-gap: 10px;
  }

  .ops-feedback-question {
    width: 300px;
  }

  #ops-feedback-comment {
    grid-area: ops-comment;
  }

  #commentsFeedback {
    height: 80%;
  }

  .rating-star {
    width: 20px;
    height: 20px;
  }
}

/* ==================== Landscape mobile ====================== */
@media only screen and (max-width: 850px) and (min-width: 500px) {
  /* ===============  FEEDBACK SECTION ==================== */
  .ops-feedback-rating-container {
    column-gap: 5px;
  }

  .ops-feedback-rating {
    column-gap: 20px;
  }

  .ops-feedback-label {
    min-width: 120px;
    max-width: fit-content;
  }

  .ops-feedback-emoji {
    height: 15px;
    width: 15px;
  }

  .rating-label {
    height: 15px;
    width: 15px;
  }

  .rating-star {
    width: 15px;
    height: 15px;
    margin-right: 0px;
  }

  .ops-feedback-input {
    column-gap: 10px;
  }

  #ops-feedback-comment {
    width: 100%;
  }

  #commentsFeedback {
    height: 80%;
  }

  .ops-feedback-question {
    width: 100%;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 850px) {
  /* ===============  FEEDBACK SECTION ==================== */
  .ops-feedback-rating-container {
    column-gap: 5px;
  }

  .ops-feedback-rating {
    column-gap: 20px;
  }

  .ops-feedback-label {
    min-width: 120px;
    max-width: fit-content;
  }

  .rating-label {
    height: fit-content;
    width: fit-content;
  }

  .rating-star {
    width: 15px;
    height: 15px;
    margin-right: 0px;
  }

  .ops-feedback-input {
    column-gap: 10px;
  }

  #ops-feedback-comment {
    width: 100%;
  }

  #commentsFeedback {
    height: 80%;
  }

  .ops-feedback-question {
    width: 100%;
  }
}

/* ####################### Tablet ################################## */

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  /* ======================== FILE INFO SECTION =================== */
  .file-info-head {
    font-size: var(--sm-font);
  }

  .file-info-item-head {
    font-size: var(--s-font);
    font-weight: bold;
  }

  .rating-star {
    height: 3vh;
    width: 3vh;
  }

  .file-info-file-download-btn svg {
    font-size: 1.8vh;
  }

  .file-info-file-download-btn {
    gap: 0.6vh;
    font-size: var(--s-font);
  }

  /* ======================= FEEDBACK SECTION ======================= */
  /* ================== Feedback Form =================== */
  .ops-feedback-input {
    height: auto;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-row-gap: 1.5vh;
    grid-template-areas:
      "ops-quality-feedback"
      "ops-service-feedback"
      "ops-message-feedback";
  }

  .ops-feedback-emoji {
    height: fit-content;
    width: fit-content;
  }

  .ops-dashboard-delivery-details p {
    width: 100%;
  }

  .rating-label {
    height: fit-content;
    width: fit-content;
  }

  /* .ops-feedback-label{
        margin-bottom: 5px;
    }   */

  .ops-feedback-question {
    row-gap: 0.5vh;
    width: auto;
  }

  #ops-feedback-comment {
    grid-area: ops-comment;
    row-gap: 10px;
  }

  #commentsFeedback {
    height: 50%;
  }

  .ops-feedback-submit-btn {
    font-size: var(--m-font);
  }

  #ops-feedback-submit-btn {
    height: auto;
  }

  /* ============ Comments Messages ================ */
  .comments-section {
    /* background: rgba(17, 41, 73, 0.4); */
    font-size: var(--s-font);
    padding: 0.8vh;
  }

  .comment-msg {
    /* background: rgba(17, 41, 73, 0.4); */
    min-width: 50%;
    max-width: 80%;
  }

  /* ======= Comment Feedback Msg ============*/
  .comment-msg-feedback {
    grid-template-columns: max-content 1fr;
  }

  .comment-feedback-quality {
    gap: 0.5vh;
  }

  .comment-feedback-quality .rating-star {
    height: 2vh;
    width: 2vh;
  }

  /* ======= Comment Chat Msg ============ */
  .comment-msg-chat {
    grid-template-columns: max-content 1fr;
  }
}

/* ####################### Mobile ################################## */

@media only screen and (max-width: 500px) {
  /* ======================== FILE INFO SECTION =================== */
  .file-info-head {
    font-size: var(--sm-font);
  }

  .file-info-item-head {
    font-size: var(--s-font);
    font-weight: bold;
  }

  .rating-star {
    height: 3vh;
    width: 3vh;
  }

  .file-info-file-download-btn svg {
    font-size: 1.5vh;
  }

  .file-info-file-download-btn {
    gap: 0.6vh;
    font-size: var(--xs-font);
  }

  /* ======================= FEEDBACK SECTION ======================= */
  /* ================== Feedback Form =================== */
  .ops-feedback-input {
    height: auto;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-row-gap: 0.8vh;
    grid-template-areas:
      "ops-quality-feedback"
      "ops-service-feedback"
      "ops-message-feedback";
  }

  .ops-feedback-emoji {
    height: fit-content;
    width: fit-content;
  }

  .ops-dashboard-delivery-details p {
    width: 100%;
  }

  .rating-label {
    height: fit-content;
    width: fit-content;
  }

  /* .ops-feedback-label{
        margin-bottom: 5px;
    }   */

  .ops-feedback-question {
    row-gap: 0.5vh;
    width: auto;
  }

  #ops-feedback-comment {
    grid-area: ops-comment;
    row-gap: 10px;
  }

  #commentsFeedback {
    height: 50%;
  }

  .ops-feedback-submit-btn {
    font-size: var(--m-font);
  }

  /* ============ Comments Messages ================ */
  .comments-section {
    /* background: rgba(17, 41, 73, 0.4); */
    font-size: var(--xs-font);
    padding: 0.8vh;
  }

  .comment-msg {
    /* background: rgba(17, 41, 73, 0.4); */
    min-width: 50%;
    max-width: 90%;
  }

  /* ======= Comment Feedback Msg ============*/
  .comment-msg-feedback {
    grid-template-columns: max-content 1fr;
  }

  .comment-feedback-quality {
    gap: 0.5vh;
  }

  .comment-feedback-quality .rating-star {
    height: 2vh;
    width: 2vh;
  }

  /* ======= Comment Chat Msg ============ */
  .comment-msg-chat {
    grid-template-columns: max-content 1fr;
  }
}
