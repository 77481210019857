/* ====================== Batch Delivery form Card ================== */
.batch-delivery-form-card {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: repeat(5, max-content);
  gap: 1vh 5vw;
  grid-template-areas:
    "group-assignment services services"
    "file-input assignment-name assignment-name"
    "file-output interim-output committed-delivery"
    "batch-delivery-form-external-file-link batch-delivery-form-email-attachment batch-delivery-form-email-attachment"
    "delivery-notes delivery-notes delivery-notes";
  background-color: var(--background-blue);
  border-radius: 1vh;
  height: fit-content;
}

#batch-delivery-form-group-assignment {
  grid-area: group-assignment;
}

#batch-delivery-form-services {
  grid-area: services;
}

#batch-delivery-form-file-input {
  grid-area: file-input;
}

#batch-delivery-form-file-output {
  grid-area: file-output;
}

#batch-delivery-form-assignment-name {
  grid-area: assignment-name;
}

#batch-delivery-form-interim-output {
  grid-area: interim-output;
}

#batch-delivery-form-committed-delivery {
  grid-area: committed-delivery;
}

#batch-delivery-form-email-attachment {
  grid-area: batch-delivery-form-email-attachment;
}

#batch-delivery-form-external-file-link {
  grid-area: batch-delivery-form-external-file-link;
}

#batch-delivery-form-delivery-notes {
  grid-area: delivery-notes;
}

.batch-delivery-form-info {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

.batch-delivery-form-info-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1vh;
  color: var(--dark-grey);
}

.batch-delivery-form-info-value {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}

.batch-delivery-form-info-input {
  background-color: var(--white);
  border-color: var(--light-grey);
}

#batch-delivery-form-info-radio {
  display: flex;
  flex-direction: row;
  gap: 5vh;
  align-items: center;
  width: 100%;
}

#batch-delivery-form-info-radio .batch-delivery-form-info-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vh;
}

#batch-delivery-form-delivery-notes .batch-delivery-form-info-input {
  width: 100%;
  height: 5vh;
}

.batch-delivery-form-committed-delivery-datetime-container {
  display: flex;
  flex-direction: row;
  gap: 1vh;
  background-color: transparent;
}

.batch-delivery-form-committed-delivery-datetime-input-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: 1vh;
}

.batch-delivery-form-committed-delivery-datetime-input-container svg {
  position: absolute;
  right: 1vh;
  z-index: 0;
}

.batch-delivery-form-committed-delivery-datetime-input {
  width: fit-content;
  background-color: transparent;
  z-index: 1;
}

/* ============== Slider css in external link ==================*/

.ops-dashboard-external-input-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5vh;
}

.ops-dashboard-input-switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 3.6vh;
  height: 1.7vh;
}

.ops-dashboard-input-switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ops-dashboard-input-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1vh;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.ops-dashboard-input-switch-slider:before {
  position: absolute;
  content: "";
  height: 1.2vh;
  width: 1.2vh;
  left: 0.3vh;
  border-radius: 50%;
  background-color: white;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.ops-dashboard-input-switch-input:checked + .ops-dashboard-input-switch-slider {
  background-color: var(--light-blue);
}

.ops-dashboard-input-switch-input:focus + .ops-dashboard-input-switch-slider {
  box-shadow: 0 0 1px var(--light-grey);
}

.ops-dashboard-input-switch-input:checked
  + .ops-dashboard-input-switch-slider:before {
  -webkit-transform: translate(2vh, -50%);
  -ms-transform: translate(2vh, -50%);
  transform: translate(1.8vh, -50%);
}

@media only screen and (max-width: 500px) {
  .batch-delivery-form-card {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(10, max-content);
    gap: 1vh 5vw;
    grid-template-areas:
      "group-assignment"
      "services"
      "file-input"
      "assignment-name"
      "file-output"
      "batch-delivery-form-external-file-link"
      "interim-output"
      "batch-delivery-form-email-attachment"
      "committed-delivery"
      "delivery-notes";
    background-color: var(--background-blue);
    border-radius: 1vh;
    height: fit-content;
  }

  #batch-delivery-form-delivery-notes .batch-delivery-form-info-input {
    height: 10vh;
  }

  .batch-delivery-form-committed-delivery-datetime-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .batch-delivery-form-committed-delivery-datetime-input {
    width: 100%;
  }
}
