@media only screen and (max-width: 500px) {
  .ops-dashboard-container {
    grid-template-rows: 8vh 86vh 8vh;
    grid-template-columns: 100vw;
    grid-template-areas:
      "topnav"
      "dashboard"
      "sidenav";
  }

  .ops-dashboard-back-btn {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    font-size: 20px;
    background: transparent;
    border: none;
    width: fit-content;
    height: fit-content;
    margin-right: 5px;
  }

  /* ================= DASHBOARD =============== */

  /* ========================== Bottom Dashboard =========================== */

  .bottom-dashboard {
    max-height: 65vh;
    overflow-x: hidden;
    font-size: var(--sm-font);
  }

  .ops-shared-users-container-head-value {
    font-size: var(--xs-font);
  }

  /* ============== OPS MODAL =========================== */
  .ops-modal-container {
    width: calc(100% - 20px);
  }

  .ops-modal-container-head {
    font-size: var(--sm-font);
  }

  .ops-modal-head {
    height: min-content;
  }

  .ops-modal-container-close-btn {
    font-size: var(--sm-font);
  }

  /* ================= MODAL ========================== */
  .ops-encrypted-modal-container {
    width: 80vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .ops-encrypted-modal-container p {
    margin: 10px auto;
  }
  .ops-encrypted-btn-container {
    row-gap: 10px;
    flex-direction: column;
  }
}
